import React, { useContext, useEffect } from 'react'
import cookie from 'react-cookies'
import NavBar from '../layout/NavBar'
import TopBar from '../layout/TopBar'
import CreatePromo from './pages/CreatePromo'
import DiscountPromoContextProvider from './context/DiscountPromoContext'
import BonusPromoContextProvider, { BonusPromoContext } from './context/BonusPromoContext'
import { GlobalContext } from '../global_context/GlobalContext'
import CreatePromoView from './CreatePromoView'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

const CreatePromoApp = () => {
  const searchPlaceholder = 'Buscar promociones por código o nombre de producto'

  const { variants } = useContext(GlobalContext)

  useEffect(() => {
    isUserLoggedIn()
  }, [])

  const isUserLoggedIn = () => {
    fetch(BACKEND_URL + '/users/current', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      }
    })
      .then((response) => response.json())
      .then((data) => {
        if (Object.keys(data).length === 0) {
          window.location = '/'
        }
      })
      .catch((e) => console.log(e))
  }

  const handleSearch = (event) => {
    // search action occurs after ENTER is pressed
    if (event.keyCode === 13) {
      // to disbale default submit form
      event.preventDefault()
      const query = event.target.value
      const val = window.confirm(
        '¿Esta seguro que quiere continuar? Los cambios editados no seran guardados'
      )
      if (val) {
        if (query !== '') {
          // redirecting to index accroding to clicked
          window.location = '/promociones?query=' + query
        }
      }
      event.target.value = ''
    }
  }

  return (
    <div>
      <div>
        <div onKeyDown={handleSearch}>
          <TopBar searchPlaceholder={searchPlaceholder} />
        </div>
        <div>
          {variants.shouldViewUpgradePromo ? (
            <BonusPromoContextProvider>
              <DiscountPromoContextProvider>
                <CreatePromo />
              </DiscountPromoContextProvider>
            </BonusPromoContextProvider>
          ) : (
            <CreatePromoView />
          )}
        </div>
      </div>
      <div>
        <NavBar activeItem="Promociones" isEditing={true} />
      </div>
    </div>
  )
}

export default CreatePromoApp
