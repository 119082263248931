import React, { useContext, useState } from 'react'
import cookie from 'react-cookies'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import history from '../../helpers/history'
import { DiscountPromoContext } from '../context/DiscountPromoContext'
import { getDiscountPrice } from '../../helpers/utils'
import { BonusPromoContext } from '../context/BonusPromoContext'
import { getDay, getMonth } from '../../helpers/formatDate'
import _ from 'lodash'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

const CreatePromo = () => {
  // const [discountProducts, setProductsSelected] = useState([])
  // const [priceReductionProducts, setPriceReductionProducts] = useState([])
  const [scalePrices, setScalePrices] = useState([{ min: '1', max: 'INFINITY', price: '' }])
  const [appliesType, setAppliesType] = useState('')
  const [appliesTo, setAppliesTo] = useState([])

  const [failedCreation, setFailedCreation] = useState(false)
  const [failedCreationMessage, setFailedCreationMessage] = useState('')
  const [clientOptions, setClientOptions] = useState([])
  const [priceListOptions, setPriceListOptions] = useState([])
  const [productOptions, setProductOptions] = useState([])
  const [promoType, setPromoType] = useState('')
  const [startTime, setStartTime] = useState('')
  const [endTime, setEndTime] = useState('')
  const business_id = 1
  const [baseProductIds, setBaseProductIds] = useState([])
  const [bonusProductIds, setBonusProductIds] = useState([])
  const [baseQuantity, setBaseQuantity] = useState(0)
  const [bonusQuantity, setBonusQuantity] = useState(0)
  const [scalePricesErrorMessage, setScalePricesErrorMessage] = useState('')

  const PRICE_REDUCTION = 'price_reduction'
  const BONUS_PRODUCT = 'bonus_product'

  const {
    discountProducts,
    addDiscountProduct,
    removeDiscountProduct,
    changeDiscountProduct,
    clearDiscountProduct
  } = useContext(DiscountPromoContext)

  const {
    bonusProducts,
    addBonusProduct,
    changeBaseQuantityProduct,
    changeBasePackageProduct,
    changeBonusQuantityProduct,
    changeBonusPackageProduct,
    clearBonusProduct
  } = useContext(BonusPromoContext)

  const handleChange = (event) => {
    event.preventDefault()
    const obj = {}
    obj[event.target.name] = event.target.value
    // when these values are reset product selected
    // should be reset in order to hide and empty table
    if (event.target.name === 'applies_target_to_type' || event.target.name === 'promoType') {
      if (event.target.name === 'applies_target_to_type') {
        clearDiscountProduct()
        clearBonusProduct()
        setAppliesTo([])
        setScalePrices([{ min: '1', max: 'INFINITY', price: '' }])
        setAppliesType(obj.applies_target_to_type)
        console.log('PASO 2', obj)
      } else {
        clearDiscountProduct()
        clearBonusProduct()
        setAppliesType('')
        setAppliesTo([])
        setPromoType(obj.promoType)
        console.log('PASO 1', obj)
      }
    } else {
      setStartTime(obj.startTime)
      setEndTime(obj.endTime)
    }
  }

  const handleStartTimeChange = (event) => {
    event.preventDefault()
    const obj = {}
    obj[event.target.name] = event.target.value
    setStartTime(obj.startTime)
  }

  const handleEndTimeChange = (event) => {
    event.preventDefault()
    const obj = {}
    obj[event.target.name] = event.target.value
    setEndTime(obj.endTime)
  }

  const handlePriceReductionInputChange = (event, product) => {
    const discount = event.target.value
    changeDiscountProduct({
      discount: discount,
      id: product.id,
      price: product.price
    })
  }

  const handleBaseQuantityInputChange = (event, product) => {
    const baseQuantity = event.target.value
    changeBaseQuantityProduct({
      baseQuantity: baseQuantity,
      id: product.id
    })
  }

  const handleBasePackageInputChange = (event, product) => {
    const basePackage = event.target.value
    const getPackage = _.find(bonusProducts[0].package, { package: basePackage })
    changeBasePackageProduct({
      basePackage: getPackage.package,
      basePackageQuantity: getPackage.quantity_per_package,
      id: product.id
    })
  }

  const handleBonusQuantityInputChange = (event, product) => {
    const bonusQuantity = event.target.value
    changeBonusQuantityProduct({
      bonusQuantity: bonusQuantity,
      id: product.id
    })
  }

  const handleBonusPackageInputChange = (event, product) => {
    const bonusPackage = event.target.value
    changeBonusPackageProduct({
      bonusPackage: bonusPackage,
      id: product.id
    })
  }

  // in order to disable submit event triggered
  // by pressing the enter key in input field
  const onKeyPress = (event) => {
    if (event.which === 13 /* Enter */) {
      event.preventDefault()
    }
  }

  const searchForClient = (event) => {
    resetProductSelected(event)
    event.preventDefault()
    const query = event.target.value
    if (query.length > 1) {
      fetch(BACKEND_URL + '/businesses/1/clients?q=' + query, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      })
        .then((response) => response.json())
        .then((data) => setClientOptions(data['table']))
        .catch((e) => console.log(e))
    }
    const targetValue = event.target.value
    const element = document.getElementsByName(targetValue)[0]
    if (element) {
      const client_id = element.innerText
      if (client_id) {
        setAppliesTo([client_id])
      }
    }
  }

  const searchForPriceList = (event) => {
    resetProductSelected(event)
    event.preventDefault()
    const query = event.target.value
    if (query.length > 1) {
      fetch(BACKEND_URL + '/businesses/1/price_lists?q=' + query, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      })
        .then((response) => response.json())
        .then((data) => setPriceListOptions(data['table']))
        .catch((e) => console.log(e))
    }
    const targetValue = event.target.value
    const element = document.getElementsByName(targetValue)[0]
    if (element) {
      const price_list_id = element.innerText
      if (price_list_id) {
        setAppliesTo([price_list_id])
      }
    }
  }

  const filterProducts = (data) => {
    const selected_product_ids = discountProducts.map((obj) => obj.id)
    const not_selected_products = data.filter((obj) => selected_product_ids.indexOf(obj.id) < 0)
    setProductOptions(not_selected_products)
  }

  const fetchProducts = (event) => {
    event.preventDefault()
    const query = event.target.value
    if (query.length > 2) {
      if (appliesType === 'price_list') {
        fetch(BACKEND_URL + '/price_lists/' + appliesTo[0] + '/product_pricelist?q=' + query, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + cookie.load('token')
          }
        })
          .then((response) => response.json())
          .then((data) => filterProducts(data['table']))
          .catch((e) => console.log(e))
      } else if (appliesType === 'client') {
        fetch(BACKEND_URL + '/clients/' + appliesTo[0] + '/product_pricelist?q=' + query, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + cookie.load('token')
          }
        })
          .then((response) => response.json())
          .then((data) => filterProducts(data['table']))
          .catch((e) => console.log(e))
      }
    }
    const targetValue = event.target.value
    const element = document.getElementsByName(targetValue)[0]
    if (element) {
      const product_id = element.innerText
      if (product_id) {
        const selectedObj = productOptions.filter((obj) => parseInt(product_id) === obj.id)[0]
        if (selectedObj) {
          paramsToPostToServer(selectedObj)
          event.target.value = ''
        }
      }
    }
  }

  const paramsToPostToServer = (selectedObj) => {
    if (promoType === PRICE_REDUCTION) {
      addDiscountProduct({
        ...selectedObj
      })
    }
    if (promoType === BONUS_PRODUCT) {
      addBonusProduct({
        ...selectedObj
      })
    }
  }

  const resetProductSelected = (event) => {
    event.preventDefault()
    clearDiscountProduct()
    clearBonusProduct()
    // setPriceReductionProducts([])
    setScalePrices([{ min: '1', max: 'INFINITY', price: '' }])
    setScalePricesErrorMessage('')
  }

  const deleteProductRowFromTable = (event, product) => {
    removeDiscountProduct(product)
  }

  const cancelCreate = (event) => {
    event.preventDefault()
    const val = window.confirm(
      '¿Esta seguro que quiere continuar? Los cambios editados no seran guardados'
    )
    if (val) {
      window.location = '/promociones'
    }
  }

  const sendCreateParams = (event) => {
    event.preventDefault()
    const missingPrice = scalePrices.filter((obj) => obj.price === '')
    const notConsecutive = scalePrices.filter((obj) => parseInt(obj.max) <= parseInt(obj.min))
    const price_zero = discountProducts.filter((obj) => obj.discount === 0)
    const bonus_quantity_zero = bonusProducts.filter(
      (obj) => obj.base_quantity <= 0 || obj.bonus_quantity <= 0
    )
    if (promoType === 'scalePrices_reduction' && notConsecutive.length > 0) {
      setScalePricesErrorMessage('Las escalas deben ser consecutivas')
      setFailedCreation(true)
      setFailedCreationMessage('Hay un error en el formulario, verifique la informacion.')
    } else if (promoType === 'scalePrices_reduction' && missingPrice.length > 0) {
      setScalePricesErrorMessage('Por favor agregue un precio de oferta para poder agregar escala')
      setFailedCreation(true)
      setFailedCreationMessage('Hay un error en el formulario, verifique la informacion.')
    } else if (promoType === 'price_reduction' && price_zero.length > 0) {
      setFailedCreation(true)
      setFailedCreationMessage(
        'Hay un error en el formulario, verifique la información, Existen descuentos en 0.'
      )
    } else if (promoType === BONUS_PRODUCT && bonus_quantity_zero.length > 0) {
      setFailedCreation(true)
      setFailedCreationMessage(
        'Hay un error en el formulario, verifique la información, Existen bonificaciones con cantidades en 0.'
      )
    } else {
      const body = {
        start_time: startTime,
        end_time: endTime,
        business_id: 1,
        applies_to_type: appliesType,
        applies_to: appliesTo
      }
      if (promoType === PRICE_REDUCTION) {
        body['products'] = discountProducts
        postCreatePriceReductionPromo(body)
      }
      if (promoType === BONUS_PRODUCT) {
        body['products'] = bonusProducts
        postCreateBonusProductPromo(body)
      }
    }
  }

  const postCreatePriceReductionPromo = (body) => {
    fetch(BACKEND_URL + '/price_reduction_promos', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      },
      body: JSON.stringify(body)
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => evaluateResponse(obj))
      .catch((e) => console.log(e))
  }

  const postCreateBonusProductPromo = (body) => {
    fetch(BACKEND_URL + '/bonus_product_promos', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      },
      body: JSON.stringify(body)
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => evaluateResponse(obj))
      .catch((e) => console.log(e))
  }

  const evaluateResponse = (obj) => {
    // evaluate responses for status 422 or 500
    if (obj.status === 422) {
      setFailedCreation(true)
      setFailedCreationMessage(Object.keys(obj.body).map((k) => obj.body[k]))
    } else if (obj.status === 500) {
      setFailedCreation(true)
      setFailedCreationMessage('Hay un error en el formulario, verifique la informacion.')
    } else if (obj.status === 404) {
      setFailedCreation(true)
      setFailedCreationMessage('Hay un error en el formulario, verifique la informacion.')
    } else {
      history.push({
        pathname: '/promociones',
        params: { successCreateForNewPromo: true }
      })
    }
  }
  console.log('select', bonusProducts)
  return (
    <div className="main-view">
      {failedCreation === true && (
        <div className="alert alert-danger" role="alert">
          <strong>{failedCreationMessage}</strong>
        </div>
      )}
      <div>
        <div className="show-title">
          <h2>Promoción nueva</h2>
        </div>
        <div className="index-table">
          <div className="show-area">
            <div className="create-promo-wrapper">
              <form onSubmit={sendCreateParams.bind(this)}>
                <div className="inline-block full-width">
                  <div className="float-left">
                    <h4>Ingrese los siguientes datos para crear una promoción nueva</h4>
                  </div>
                  <div className="float-right">
                    <button onClick={cancelCreate} className="cancel-button">
                      Cancelar
                    </button>
                    <input className="save-button" value="Guardar" type="submit" />
                  </div>
                </div>
                <hr />
                <div className="promo-form-section mt-33">
                  <div className="title-promos-form">
                    <b>PASO 1: Escoger tipo de promoción</b>
                  </div>
                  <hr className="hr-promos-form" />
                  <select
                    onChange={handleChange}
                    className="select-promo-type"
                    name="promoType"
                    required
                  >
                    <option value="" hidden>
                      Escoger una opcion
                    </option>
                    <option value="bonus_product">Bonificación de una referencia</option>
                    <option value="price_reduction">Precio por descuento</option>
                  </select>
                </div>

                <div className="promo-form-section">
                  <div className="title-promos-form">
                    <b>
                      PASO 2: Escoger si la promoción le aplica a un cliente individual o lista de
                      precio
                    </b>
                  </div>
                  <hr className="hr-promos-form" />
                  {promoType === '' && (
                    <div>
                      <select
                        className="select-appliesType inactive"
                        name="applies_target_to_type"
                        required
                        disabled
                      >
                        <option value="" hidden>
                          Escoger una opcion
                        </option>
                      </select>
                    </div>
                  )}

                  {promoType !== '' && (
                    <div>
                      <select
                        onChange={handleChange}
                        className="select-appliesType"
                        name="applies_target_to_type"
                        value={appliesType}
                        required
                      >
                        <option value="" hidden>
                          Escoger una opcion
                        </option>
                        <option value="client">Cliente individual</option>
                        <option value="price_list">Lista de precio</option>
                      </select>
                    </div>
                  )}
                </div>

                {appliesType === 'client' && (
                  <div className="promo-form-section">
                    <div className="title-promos-form">
                      <b>2.1: Escoger el cliente a quien le aplica la promoción</b>
                    </div>
                    <div className="mt-16">Cliente</div>
                    <input
                      autoComplete="off"
                      onChange={searchForClient.bind(this)}
                      onKeyPress={onKeyPress}
                      className="search-input-border"
                      placeholder="Escribir nombre o codigo del cliente"
                      name="appliesTo"
                      list="all-clients"
                      required
                    />
                    <datalist id="all-clients">
                      {/*<option key={obj.id} id={obj.id}>{obj.db_ref + " - " +  obj.name}</option> */}
                      {clientOptions.map((obj) => (
                        <option
                          key={obj.id}
                          id={obj.id}
                          name={obj.db_ref + ' - ' + obj.name}
                          value={obj.db_ref + ' - ' + obj.name}
                        >
                          {obj.id}
                        </option>
                      ))}
                    </datalist>
                    <span className="icon-search">
                      <FontAwesomeIcon className="icon-search" icon={faSearch} />
                    </span>
                  </div>
                )}

                {appliesType === 'price_list' && (
                  <div className="promo-form-section">
                    <div className="title-promos-form">
                      <b>2.1: Escoger la lista de precio a quien le aplica la promoción</b>
                    </div>
                    <div className="mt-16">Lista de Precio</div>
                    <input
                      autoComplete="off"
                      onChange={searchForPriceList}
                      className="search-input-border"
                      placeholder="Escribir nombre o codigo de la lista de precio"
                      name="appliesTo"
                      list="all-price-lists"
                      onKeyPress={onKeyPress}
                      required
                    />
                    <datalist id="all-price-lists">
                      {priceListOptions.map((obj) => (
                        <option
                          key={obj.id}
                          id={obj.id}
                          name={obj.db_ref + ' - ' + obj.name}
                          value={obj.db_ref + ' - ' + obj.name}
                        >
                          {obj.id}
                        </option>
                      ))}
                    </datalist>
                    <span className="icon-search">
                      <FontAwesomeIcon className="icon-search" icon={faSearch} />
                    </span>
                  </div>
                )}

                <div className="promo-form-section">
                  <div className="title-promos-form">
                    <b>PASO 3: Escoger el producto al que le aplica esta promoción</b>
                  </div>
                  <hr className="hr-promos-form" />
                  <div>Producto</div>
                  {appliesTo.length === 0 && (
                    <input
                      autoComplete="off"
                      onChange={fetchProducts}
                      onKeyPress={onKeyPress}
                      className="search-input-border inactive"
                      list="all-products"
                      placeholder="Escribir nombre o codigo del producto"
                      required
                      disabled
                    />
                  )}
                  {appliesTo.length === 0 && (
                    <span className="icon-search">
                      <FontAwesomeIcon className="icon-search inactive" icon={faSearch} />
                    </span>
                  )}
                  {appliesTo.length !== 0 &&
                    discountProducts.length === 0 &&
                    promoType === PRICE_REDUCTION && (
                      <>
                        <input
                          autoComplete="off"
                          onChange={fetchProducts}
                          onKeyPress={onKeyPress}
                          className="search-input-border"
                          placeholder="Escribir nombre o codigo del producto"
                          list="all-products"
                          required
                        />
                        <span className="icon-search">
                          <FontAwesomeIcon className="icon-search" icon={faSearch} />
                        </span>
                      </>
                    )}
                  {appliesTo.length !== 0 &&
                    discountProducts.length > 0 &&
                    promoType === PRICE_REDUCTION && (
                      <>
                        <input
                          autoComplete="off"
                          onChange={fetchProducts}
                          onKeyPress={onKeyPress}
                          className="search-input-border"
                          placeholder="Escribir nombre o codigo del producto"
                          list="all-products"
                        />
                        <span className="icon-search">
                          <FontAwesomeIcon className="icon-search" icon={faSearch} />
                        </span>
                      </>
                    )}

                  {appliesTo.length !== 0 &&
                    bonusProducts.length === 0 &&
                    promoType === BONUS_PRODUCT && (
                      <>
                        <input
                          autoComplete="off"
                          onChange={fetchProducts}
                          onKeyPress={onKeyPress}
                          className="search-input-border"
                          placeholder="Escribir nombre o codigo del producto"
                          list="all-products"
                          required
                        />
                        <span className="icon-search">
                          <FontAwesomeIcon className="icon-search" icon={faSearch} />
                        </span>
                      </>
                    )}
                  {appliesTo.length !== 0 &&
                    bonusProducts.length > 0 &&
                    promoType === BONUS_PRODUCT && (
                      <>
                        <input
                          autoComplete="off"
                          onChange={fetchProducts}
                          onKeyPress={onKeyPress}
                          className="search-input-border inactive"
                          placeholder="Escribir nombre o codigo del producto"
                          list="all-products"
                          disabled
                        />
                        <span className="icon-search">
                          <FontAwesomeIcon className="icon-search" icon={faSearch} />
                        </span>
                      </>
                    )}

                  <datalist id="all-products">
                    {productOptions.map((obj, index) => (
                      <option
                        key={index}
                        id={obj.id}
                        name={obj.db_ref + ' - ' + obj.name}
                        value={obj.db_ref + ' - ' + obj.name}
                      >
                        {obj.id}
                      </option>
                    ))}
                  </datalist>
                </div>

                <div className="promo-form-section row">
                  {bonusProducts.length > 0 && promoType === BONUS_PRODUCT && (
                    <table className="table table-hover promos-product-table">
                      <thead>
                        <tr>
                          <th className="pl-40">CODIGO</th>
                          <th>NOMBRE DEL PRODUCTO</th>
                          <th>UNIDAD</th>
                          <th>PRECIO</th>
                          <th>PRECIO X UNIDAD</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="pl-40">{bonusProducts[0].db_ref}</td>
                          <td>{bonusProducts[0].name}</td>
                          <td>{bonusProducts[0].package[0].description}</td>
                          <td>${Number(bonusProducts[0].price).toFixed(2)}</td>

                          {!bonusProducts[0].has_package_per_unit && (
                            <td>
                              <span>{'-'}</span>
                            </td>
                          )}
                          {bonusProducts[0].has_package_per_unit && (
                            <td>
                              <span>
                                {' '}
                                $
                                {(
                                  bonusProducts[0].price / bonusProducts[0].quantity_per_package
                                ).toFixed(2)}
                              </span>
                            </td>
                          )}

                          <td className="delete-selected-product" onClick={resetProductSelected}>
                            &times;
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                  {discountProducts.length > 0 && promoType === 'price_reduction' && (
                    <table className="table table-hover promos-product-table">
                      <thead>
                        <tr>
                          <th className="pl-40">CODIGO</th>
                          <th>NOMBRE DEL PRODUCTO</th>
                          <th>UNIDAD</th>
                          <th>PRECIO</th>
                          <th>PRECIO X UNIDAD</th>
                          <th>PORCENTAJE DE DESCUENTO</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        {discountProducts.map((product, index) => (
                          <tr key={index}>
                            <td className="pl-40">{product.db_ref}</td>
                            <td>{product.name}</td>
                            <td>
                              {`${product.package.description} ${
                                product.quantity_per_package > 1
                                  ? `de ${product.quantity_per_package}`
                                  : ''
                              }`}
                            </td>

                            <td>
                              {product.discount > 0 && (
                                <>
                                  <span style={{ color: '#FF0000' }}>
                                    $
                                    {getDiscountPrice(
                                      product.originalPrice,
                                      product.discount
                                    ).toFixed(2)}
                                    <br />
                                  </span>
                                  <span style={{ textDecoration: 'line-through' }}>
                                    {product.originalPrice}
                                  </span>
                                </>
                              )}
                              {product.discount <= 0 && <span>{product.originalPrice}</span>}
                            </td>

                            {!product.has_package_per_unit && (
                              <td>
                                <span>{'-'}</span>
                              </td>
                            )}
                            {product.discount <= 0 && product.has_package_per_unit && (
                              <td>
                                <span>
                                  {' '}
                                  $
                                  {(product.originalPrice / product.quantity_per_package).toFixed(
                                    2
                                  )}
                                </span>
                              </td>
                            )}

                            {product.discount > 0 && product.has_package_per_unit && (
                              <td>
                                <>
                                  <span style={{ color: '#FF0000' }}>
                                    $
                                    {getDiscountPrice(
                                      product.originalPrice / product.quantity_per_package,
                                      product.discount
                                    ).toFixed(2)}
                                    <br />
                                  </span>
                                  <span style={{ textDecoration: 'line-through' }}>
                                    $
                                    {(product.originalPrice / product.quantity_per_package).toFixed(
                                      2
                                    )}
                                  </span>
                                </>
                              </td>
                            )}

                            <td>
                              <div className="input-group show-buttons">
                                <input
                                  id={index}
                                  type="number"
                                  min="0"
                                  max="100"
                                  step="0.00001"
                                  autoComplete="off"
                                  name="price"
                                  value={product.discount}
                                  onChange={(e) => handlePriceReductionInputChange(e, product)}
                                  className="price-discount-input"
                                  placeholder={0.0}
                                  required
                                />
                                %
                              </div>
                            </td>
                            <td
                              id={index}
                              className="delete-selected-product"
                              onClick={(e) => deleteProductRowFromTable(e, product)}
                            >
                              &times;
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}

                  {bonusProducts.length > 0 && promoType === BONUS_PRODUCT && (
                    <div className="promo-form-section">
                      <div className="title-promos-form" style={{ paddingLeft: 16 }}>
                        <b>3.1: Establece la regla de esta promoción</b>
                        <p className="mt-8">
                          Nota: Actualmente las promociones de bonificación son recurrentes. Cada
                          vez que el usuario llega al mínimo de la regla, se aplica la bonificación.
                        </p>
                      </div>
                      <div className="mt-16 form-row">
                        <div className="form-group col-md-2">
                          <div> Base </div>
                          <input
                            min="0"
                            step="1.0"
                            type="number"
                            autoComplete="off"
                            name="base_quantity"
                            onChange={(e) => handleBaseQuantityInputChange(e, bonusProducts[0])}
                            className="form-control"
                            placeholder="0"
                            required
                          />
                        </div>
                        <div className="form-group col-md-2">
                          <div> Unidad de Venta </div>
                          <select
                            onChange={(e) => handleBasePackageInputChange(e, bonusProducts[0])}
                            className="form-control"
                            name="base_package_promo"
                            required
                          >
                            <option value="" hidden />
                            {bonusProducts[0].package.map((obj) => (
                              <option
                                key={obj.id}
                                id={obj.id}
                                name={obj.description}
                                value={obj.package}
                              >
                                {obj.description}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="form-group col-md-1 mt-20">
                          <span className="plus-span">+</span>
                        </div>
                        <div className="form-group col-md-2">
                          <div>Bonificación</div>
                          <input
                            min="0"
                            step="1.0"
                            type="number"
                            autoComplete="off"
                            name="bonus_quantity"
                            onChange={(e) => handleBonusQuantityInputChange(e, bonusProducts[0])}
                            className="form-control"
                            placeholder="0"
                            required
                          />
                        </div>
                        <div className="form-group col-md-3">
                          <div>Unidad de Venta Bonificada</div>
                          <select
                            onChange={(e) => handleBonusPackageInputChange(e, bonusProducts[0])}
                            className="form-control"
                            name="bonus_package_promo"
                            required
                          >
                            <option value="" hidden />
                            {bonusProducts[0].package.map((obj) => (
                              <option
                                key={obj.id}
                                id={obj.id}
                                name={obj.description}
                                value={obj.package}
                              >
                                {obj.description}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="">
                  <div className="title-promos-form mt-8">
                    <b>PASO 4: Escoger fecha de inicio y fecha final de la promoción</b>
                  </div>
                  <hr className="hr-promos-form" />
                  <div className="inline-block">
                    <div className="float-left">
                      <label>Inicio</label>
                      <br />
                      <input
                        min={new Date().getFullYear() + '-' + getMonth() + '-' + getDay()}
                        onChange={handleStartTimeChange}
                        className="start-date"
                        type="date"
                        name="startTime"
                        required
                      />
                      <span className="dates-separator">______</span>
                    </div>
                    <div className="float-right">
                      <label>Fin</label>
                      <br />
                      <input
                        min={startTime}
                        onChange={handleEndTimeChange}
                        className="end-date"
                        type="date"
                        name="endTime"
                        required
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreatePromo
