import React from 'react'
import Pagination from '../layout/Pagination'
import { withRouter } from 'react-router-dom'

class ClientIndexView extends React.Component {
  redirectToCreateClient() {
    window.location = '/crear_cliente'
  }

  showModel = (event) => {
    event.preventDefault()
    const id = event.target.id
    this.props.history.push({
      pathname: '/clientes/' + id + '/detalles'
    })
  }

  render() {
    const data = this.props.data

    if (this.props.isFetching) {
      return (
        <div className="main-view">
          <div className="index-header">
            <br />
            <p>Obteniendo datos ...</p>
          </div>
        </div>
      )
    } else {
      return (
        <div className="main-view">
          <div className="index-header">
            <h2>{this.props.title}</h2>
            <div className="index-buttons">
              <button onClick={() => this.redirectToCreateClient()}>Crear cliente nuevo</button>
            </div>
          </div>
          <div className="index-table">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>CÓDIGO</th>
                  <th>NOMBRE DEL CLIENTE</th>
                  <th>LISTA DE PRECIO ASIGNADA</th>
                </tr>
              </thead>
              <tbody>
                {data.length > 0 &&
                  data.map((model, index) => (
                    <tr className="cursor-pointer" key={index}>
                      <td id={model['id']} onClick={(e) => this.showModel(e)}>
                        {model['db_ref']}
                      </td>
                      <td id={model['id']} onClick={(e) => this.showModel(e)}>
                        {model['name']}
                      </td>
                      {model['price_list'] && (
                        <td id={model['id']} onClick={(e) => this.showModel(e)}>
                          {model['price_list']['description']}
                        </td>
                      )}
                      {!model['price_list'] && (
                        <td id={model['id']} onClick={(e) => this.showModel(e)} />
                      )}
                    </tr>
                  ))}
                {data.length === 0 && (
                  <tr>
                    <td>No hubo resultados</td>
                  </tr>
                )}
              </tbody>
            </table>
            <nav>
              {this.props.total_pages > 1 && (
                <Pagination
                  current_page={this.props.current_page}
                  total_pages={this.props.total_pages}
                />
              )}
            </nav>
          </div>
        </div>
      )
    }
  }
}

export default withRouter(ClientIndexView)
