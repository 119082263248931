import React, { useContext } from 'react'
import { UserConfigCreateContext } from '../context/UserConfigCreateProvider'

const CreateUserInfoDetailStep = () => {
  const { errors, register } = useContext(UserConfigCreateContext)

  return (
    <>
      <div className="inline-block full-width">
        <div className="float-left">
          <h4>Detalles de Usuario</h4>
          <p style={{ color: 'red' }}>
            Por favor llene los campos obligatorios con * para poder proceder
          </p>
        </div>
      </div>
      <hr />

      <div className="form-group row">
        <label className="col-sm-3 col-form-label">Activo:</label>
        <div className="col-sm-8">
          <select
            name="active"
            {...register('active')}
            className={`form-control ${errors.active ? 'is-invalid' : ''}`}
          >
            <option value="true">Si</option>
            <option value="false">No</option>
          </select>
          <div className="invalid-feedback">{errors.active?.message}</div>
        </div>
      </div>

      <div className="form-group row show-data-form">
        <label htmlFor="inputName" className="col-sm-3 col-form-label">
          Nombre de usuario<span className="red">*</span>:
        </label>
        <div className="col-sm-8">
          <input
            name="name"
            type="text"
            {...register('name')}
            className={`form-control ${errors.name ? 'is-invalid' : ''}`}
          />
          <div className="invalid-feedback">{errors.name?.message}</div>
        </div>
      </div>

      <div className="form-group row show-data-form">
        <label htmlFor="inputCode" className="col-sm-3 col-form-label">
          Tipo de usuario:
        </label>
        <div className="col-sm-8">
          <div>App de Vendedor</div>
        </div>
      </div>

      <div className="form-group row show-data-form">
        <label htmlFor="inputCode" className="col-sm-3 col-form-label">
          Número de rutero/Código:<span className="red">*</span>:
        </label>
        <div className="col-sm-8">
          <input
            name="code"
            type="text"
            {...register('code')}
            className={`form-control ${errors.code ? 'is-invalid' : ''}`}
          />
          <div className="invalid-feedback">{errors.code?.message}</div>
        </div>
      </div>

      <div className="form-group row show-data-form">
        <label htmlFor="inputCode" className="col-sm-3 col-form-label">
          Correo electrónico<span className="red">*</span>:
        </label>
        <div className="col-sm-8">
          <input
            name="email"
            type="text"
            {...register('email')}
            className={`form-control ${errors.email ? 'is-invalid' : ''}`}
          />
          <div className="invalid-feedback">{errors.email?.message}</div>
        </div>
      </div>

      <div className="form-group row show-data-form">
        <label htmlFor="inputCode" className="col-sm-3 col-form-label">
          Contraseña<span className="red">*</span>:
        </label>
        <div className="col-sm-8">
          <input
            name="password"
            type="password"
            {...register('password')}
            className={`form-control ${errors.password ? 'is-invalid' : ''}`}
          />
          <div className="invalid-feedback">{errors.password?.message}</div>
        </div>
      </div>

      <div className="form-group row show-data-form">
        <label htmlFor="inputCode" className="col-sm-3 col-form-label">
          Reescribir Contraseña<span className="red">*</span>:
        </label>
        <div className="col-sm-8">
          <input
            name="confirmPassword"
            type="password"
            {...register('confirmPassword')}
            className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`}
          />
          <div className="invalid-feedback">{errors.confirmPassword?.message}</div>
        </div>
      </div>

      <div className="form-group row">
        <label htmlFor="inputCode" className="col-sm-3 col-form-label">
          Celular (opcional)
        </label>
        <div className="col-sm-8">
          <input
            name="mobile"
            type="number"
            {...register('mobile')}
            className={`form-control ${errors.mobile ? 'is-invalid' : ''}`}
          />
          <div className="invalid-feedback">{errors.mobile?.message}</div>
        </div>
      </div>
    </>
  )
}

export default CreateUserInfoDetailStep
