import React, { createContext, useEffect, useReducer, useState } from 'react'
import cookie from 'react-cookies'
import * as apiStatusCodes from '../../constants/apiStatusCodes'
import { useHistory } from 'react-router-dom'
import {
  getQueryState,
  getUseConfig,
  handleErrors,
  updateUserInfo
} from '../services/apiUserService'
import { UserInfoInitialStateForm } from '../helpers/formControlsVariables'
import { handleTabClick, handleUpdate } from '../helpers/UserConfigHandlers'
import validate from '../../helpers/validation'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'

const initialState = {}

const UserInfoContext = createContext(initialState)

const UserInfoProvider = (props) => {
  const [isFetching, setFetching] = useState(true)
  const [isEditingUserInfoTab, setEditingUserInfoTab] = useState(false)
  const [userInfoData, setUserInfoData] = useState({})
  const [title, setTitle] = useState('')
  const [failedCreationState, setFailedCreationState] = useState(false)
  const [failedCreationInfo, setFailedCreationInfo] = useState(null)
  const [activeTab, setActiveTab] = useState('Detalles')
  const [formIsValid, setFormIsValid] = useState(true)

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Este es un campo obligatorio.')
      .min(3, 'Asegúrese de cumplir con el mínimo de los 3 caracteres'),
    active: Yup.bool(),
    code: Yup.string()
      .required('Este es un campo obligatorio.')
      .min(3, 'Asegúrese de cumplir con el mínimo de los 3 caracteres'),
    email: Yup.string().required('Este es un campo obligatorio.').email('El formato no es válido.'),
    mobile: Yup.string().notRequired().nullable()
  })

  const formOptions = { resolver: yupResolver(validationSchema) }

  const { register, handleSubmit, reset, setValue, formState } = useForm(formOptions)
  const { errors, isValid } = formState

  const history = useHistory()

  useEffect(() => {
    fetUserInfo()
  }, [])

  const fetUserInfo = (id) => {
    setFetching(true)
    const queryParams = getQueryState()
    getUseConfig(props.user)
      .then((response) => handleErrors(response))
      .then((response) => response.json())
      .then((data) => {
        setFetching(false)
        setUserInfoData(data)
        setTitle(queryParams ? `Resultados de la búsqueda: ${queryParams}` : `Usuarios`)
        setValue('name', data.name)
        setValue('code', data.db_ref)
        setValue('email', data.salesman_email)
        setValue('mobile', data.mobile)
      })
      .catch((error) => {
        evaluateResponse(error)
        if (error.status === apiStatusCodes.UNAUTHORIZED) {
          cookie.remove('token', { path: '/' })
          window.location = '/'
        }
      })
  }

  const onSubmit = (data) => {
    updateUserInfo(userInfoData.id, data)
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => evaluateResponse(obj, false))
      .catch((e) => console.log(e))
  }

  const handleUpdateUser = (event) => {
    const value = handleUpdate(event)
    setEditingUserInfoTab(value)
  }

  const handleTabClickDetails = (event) => {
    const value = handleTabClick(event, isEditingUserInfoTab)
    setActiveTab(value.activeTab)
    setEditingUserInfoTab(value.editing)
  }

  const hideFailedCreationMessage = () => {
    setFailedCreationInfo(null)
  }

  const evaluateResponse = (obj) => {
    switch (obj.status) {
      case 422:
        setFailedCreationInfo('Verifique la informacion ingresada.')
        setTimeout(hideFailedCreationMessage, 9000)
        break
      case 409:
        setFailedCreationInfo(
          'Error al guardar cambios. El codigo ingresado ya existe. Por favor escoger otro codigo.'
        )
        setTimeout(hideFailedCreationMessage, 9000)
        break
      case 404:
        setFailedCreationInfo('Servidor fuera de servicio')
        setTimeout(hideFailedCreationMessage, 9000)
        break
      case 500:
        setFailedCreationInfo('Error interno en el sistema')

        setTimeout(hideFailedCreationMessage, 9000)
        break
      default:
        const data = obj.body
        setEditingUserInfoTab(false)
        history.push('/usuarios/' + data['id'])
        fetUserInfo()
        break
    }
  }

  return (
    <UserInfoContext.Provider
      value={{
        isFetching,
        userInfoData,
        title,
        isEditingUserInfoTab,
        activeTab,
        failedCreationInfo,
        createUserParams: props.createUserParams,
        handleUpdateUser,
        handleTabClickDetails,
        onSubmit,
        handleSubmit,
        errors,
        register
      }}
    >
      {props.children}
    </UserInfoContext.Provider>
  )
}
export { UserInfoContext, UserInfoProvider }
