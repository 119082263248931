import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDollarSign, faTrash } from '@fortawesome/free-solid-svg-icons'

export default class PriceScaleRow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      row: props.row,
      total: props.total,
      data: props.data
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ total: nextProps.total, row: nextProps.row, data: nextProps.data })
  }

  render() {
    return (
      <div style={{ display: 'inline-block' }}>
        <div style={{ float: 'left', marginTop: '40px', marginRight: '25px' }}>
          <img alt="#" src="/ellipse.png" width="40" height="40" />
          <span style={{ color: 'white', marginLeft: '-25px' }}>{this.state.row + 1}</span>
        </div>
        <div style={{ float: 'left' }}>
          <div style={{ marginBottom: '10px' }}>Minimo de venta ({this.props.unit})</div>
          {this.state.row === 0 && (
            <input
              style={{ width: '160px', height: '48px', textAlign: 'right' }}
              value={this.state.data.min}
              placeholder="1"
              type="number"
              min="1"
              name="min"
              step="1"
              onChange={(e) => {
                this.props.updateScalePrice(this.state.row, e)
              }}
            />
          )}{' '}
          {this.state.row > 0 && (
            <span style={{ paddingLeft: '130px', lineHeight: '3', marginRight: '15px' }}>
              {this.state.data.min}
            </span>
          )}
          <span style={{ color: '#A29E9D', borderBottom: '3px solid', margin: '16px' }}>
            ______
          </span>
        </div>
        <div style={{ float: 'right' }}>
          <div style={{ marginBottom: '10px' }}>Precio de oferta</div>
          <span className="icon-dollar">
            <FontAwesomeIcon icon={faDollarSign} />
          </span>
          <input
            value={this.state.data.price}
            placeholder="0.00"
            style={{ textAlign: 'right', height: '48px', width: '200px' }}
            name="price"
            step="0.000001"
            type="number"
            onChange={(e) => {
              this.props.updateScalePrice(this.state.row, e)
            }}
          />
          {this.state.total > 1 && (
            <button
              type="button"
              onClick={() => this.props.delete(this.state.row)}
              style={{
                backgroundColor: '#D94E4E',
                color: 'white',
                height: '48px',
                width: '133px',
                border: 'none',
                borderRadius: '3px',
                fontSize: '14px',
                marginLeft: '17px',
                paddingRight: '10px'
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
              Borrar escala
            </button>
          )}
        </div>
        <div style={{ float: 'right' }}>
          <div style={{ marginBottom: '10px' }}>Maximo de venta ({this.props.unit})</div>
          <input
            style={{ width: '160px', height: '48px', textAlign: 'right' }}
            value={this.state.data.max}
            placeholder="ilimitado"
            min="1"
            name="max"
            step="1"
            type="number"
            onChange={(e) => {
              this.props.updateScalePrice(this.state.row, e)
            }}
            onBlur={(e) => {
              this.props.validateConsecutiveScales(this.state.row, e)
            }}
          />
          <span style={{ margin: '16px' }}>la venta es a</span>
        </div>
      </div>
    )
  }
}
