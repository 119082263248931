import React, { useState, useContext, useEffect } from 'react'
import cookie from 'react-cookies'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import {
  faUsers,
  faUser,
  faBriefcase,
  faHandHoldingUsd,
  faShoppingCart,
  faGift,
  faSignOutAlt,
  faChartBar,
  faDonate,
  faLayerGroup
} from '@fortawesome/free-solid-svg-icons'
import Dropdown from '../components/inputs/DropDown'
import { GlobalContext } from '../global_context/GlobalContext'
import _ from 'lodash'
import validateAccessRole from '../helpers/userRole'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

const NavBar = (props) => {
  const {
    userRole,
    variants,
    hasAppEntrega,
    supervisorRole,
    configPermission,
    canViewReport,
    shouldViewCoreInfo,
    shouldViewTransactionInfo
  } = useContext(GlobalContext)

  const consolidationPermission = userRole.can_send_consolidation
    ? validateAccessRole(userRole.can_send_consolidation)
    : false

  const [userType] = useState([
    {
      id: 0,
      title: 'Vendedores', // NavBar Title
      selected: false,
      key: 'vendedores' // href Path
    },
    {
      id: 1,
      title: 'Entregadores', // NavBar Title
      selected: false,
      key: 'entregadores' // href Path
    }
  ])

  const [reportType, setReportType] = useState([{}])

  useEffect(() => {
    setReportType([
      {
        id: 0,
        title: 'Efectividad de Entregas', // NavBar Title
        selected: false,
        key: 'efectividad_de_entregas', // href Path
        active: variants.hasAppEntrega
      },
      {
        id: 1,
        title: 'Efectividad de Visitas', // NavBar Title
        selected: false,
        key: 'efectividad_de_visitas', // href Path
        active: variants.useAve
      },
      {
        id: 2,
        title: 'Efectividad de Cobros', // NavBar Title
        selected: false,
        key: 'efectividad_de_cobros', // href Path
        active: variants.useCobro
      },
      {
        id: 3,
        title: 'Consolidaciones', // NavBar Title
        selected: false,
        key: 'consolidaciones', // href Path
        active: consolidationPermission.can_read
      }
    ])
  }, [variants])

  const signOut = () => {
    fetch(BACKEND_URL + '/users/sign_out', {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      }
    }).catch((e) => console.log(e))
    cookie.remove('token', { path: '/' })
    window.location = '/logout'
  }

  const handleClick = (event) => {
    windowConfirmEvent(event)
  }

  const resetThenSet = (event, id, key) => {
    event.preventDefault()
    windowConfirmEvent(event)
  }

  const windowConfirmEvent = (event) => {
    if (event.target.tagName === 'A') {
      // to filter clicks only for navbar items
      if (event.target.innerText.toLowerCase() === 'cerrar sesión') {
        signOut()
      } else if (props.isEditing === true) {
        const val = window.confirm(
          'Esta seguro que quiere continuar? Los cambios editados no seran guardados'
        )
        if (val === true) {
          // redirecting to index accroding to clicked
          window.location = '/' + event.target.innerText.toLowerCase().replace(/ /g, '_')
        }
      } else if (event.target.innerText.toLowerCase() !== 'reportes') {
        window.location = '/' + event.target.innerText.toLowerCase().replace(/ /g, '_')
      }
    }
  }

  return (
    <div className="nav-bar" onClick={handleClick}>
      <div className="logo">
        <img className="ubiqua-logo" alt="UBIQUA" src="/ubiqua_logo_white.png" />
      </div>

      {configPermission && (
        <>
          <Link
            to="/usuarios"
            onClick={(e) => e.preventDefault()}
            className={`${
              props.activeItem === 'Usuarios'
                ? 'active-nav-bar-item cursor-pointer'
                : 'cursor-pointer'
            }`}
          >
            <FontAwesomeIcon icon={faUsers} />
            Usuarios
          </Link>
        </>
      )}

      {supervisorRole && (
        <>
          {shouldViewCoreInfo && (
            <Link
              to="/productos"
              onClick={(e) => e.preventDefault()}
              className={`${
                props.activeItem === 'Productos'
                  ? 'active-nav-bar-item cursor-pointer'
                  : 'cursor-pointer'
              }`}
            >
              <FontAwesomeIcon icon={faBriefcase} />
              Productos
            </Link>
          )}

          {shouldViewCoreInfo && (
            <Link
              to="/clientes"
              onClick={(e) => e.preventDefault()}
              className={`${
                props.activeItem === 'Clientes'
                  ? 'active-nav-bar-item cursor-pointer'
                  : 'cursor-pointer'
              }`}
            >
              <FontAwesomeIcon icon={faUser} />
              Clientes
            </Link>
          )}

          {shouldViewCoreInfo && (
            <Link
              to="/listas_de_precios"
              onClick={(e) => e.preventDefault()}
              className={`${
                props.activeItem === 'Listas de Precios'
                  ? 'active-nav-bar-item cursor-pointer'
                  : 'cursor-pointer'
              }`}
            >
              <FontAwesomeIcon icon={faHandHoldingUsd} />
              Listas de Precios
            </Link>
          )}

          {shouldViewCoreInfo && (
            <Link
              to="/promociones"
              onClick={(e) => e.preventDefault()}
              className={`${
                props.activeItem === 'Promociones'
                  ? 'active-nav-bar-item cursor-pointer'
                  : 'cursor-pointer'
              }`}
            >
              <FontAwesomeIcon icon={faGift} />
              Promociones
            </Link>
          )}

          {shouldViewTransactionInfo && (
            <Link
              to="/pedidos"
              onClick={(e) => e.preventDefault()}
              className={`${
                props.activeItem === 'Pedidos'
                  ? 'active-nav-bar-item cursor-pointer'
                  : 'cursor-pointer'
              }`}
            >
              <FontAwesomeIcon icon={faShoppingCart} />
              Pedidos
            </Link>
          )}

          {shouldViewCoreInfo && (
            <Dropdown
              headerTitle="Equipo de campo"
              icon={faUsers}
              list={userType}
              activeCursor={props.activeItem}
              resetThenSet={resetThenSet}
            />
          )}

          {canViewReport && (
            <Dropdown
              headerTitle="Reportes"
              icon={faChartBar}
              activeCursor={props.activeItem}
              list={_.filter(reportType, ['active', true])}
              resetThenSet={resetThenSet}
            />
          )}

          {hasAppEntrega && (
            <Link
              to="/liquidaciones"
              onClick={(e) => e.preventDefault()}
              className={`${
                props.activeItem === 'Liquidaciones'
                  ? 'active-nav-bar-item cursor-pointer'
                  : 'cursor-pointer'
              }`}
            >
              <FontAwesomeIcon icon={faDonate} />
              Liquidaciones
            </Link>
          )}
        </>
      )}
      <a className="sign-out-icon cursor-pointer">
        <FontAwesomeIcon icon={faSignOutAlt} />
        Cerrar Sesión
      </a>
    </div>
  )
}
export default NavBar
