export const getDiscountPrice = (price, discount) =>
  discount && discount > 0 ? price - price * (discount / 100) : price

export const capitalizeFirstLetter = (word) => word.charAt(0).toUpperCase()

export const formatPrice = (price) => `P${price}`

export const formatDate = (date) => new Date(date).toLocaleDateString('en-US')

export const formatDiscount = (data, decimals = 5) => {
  if (parseFloat(data) > 100 || parseFloat(data) < 0) {
    return 0
  }
  if (data) {
    const regex = new RegExp('(\\.\\d{' + decimals + '})\\d+', 'g')
    const validDiscountNumber = data.replace(regex, '$1')
    return parseFloat(validDiscountNumber)
  } else {
    return 0
  }
}

export function endsVowel(str) {
  for (const i of 'aeiou') {
    if (str.endsWith(i)) {
      return true
    }
  }
  return false
}
