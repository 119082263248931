import React, { useState, useContext } from 'react'
import { store } from './context/UserListProvider'
import UserPagination from './component/UserPagination'

const PaginationData = React.createContext()
export const PaginationConsumer = PaginationData.Consumer

const ListUserConfig = () => {
  const {
    usersData,
    title,
    isFetching,
    handleCreateNewDeliverUser,
    handleShowUserConfigDetails,
    paginationParams,
    totalCount,
    getCurrentPage
  } = useContext(store)

  return (
    <div>
      <div className="main-view">
        <div className="index-header">
          <h2>{title}</h2>
          <div className="index-buttons">
            <button onClick={() => handleCreateNewDeliverUser()}>Crear usuario nuevo</button>
          </div>
        </div>
        <div>
          {isFetching && usersData.length === 0 ? (
            <div className="mt-70">
              <div className="text-center">
                <br />
                <p>Obteniendo datos para usuarios ....</p>
              </div>
            </div>
          ) : (
            <>
              <div className="index-table">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>CÓDIGO</th>
                      <th>NOMBRE</th>
                      <th>TIPO DE USUARIO</th>
                      <th>CORREO ELECTRONICO</th>
                      <th>ACTIVO</th>
                    </tr>
                  </thead>
                  <tbody>
                    {usersData.length > 0 ? (
                      usersData.map((user) => (
                        <tr className="cursor-pointer" key={user.id}>
                          <td id={user['id']} onClick={handleShowUserConfigDetails}>
                            {user['db_ref']}
                          </td>
                          <td id={user['id']} onClick={handleShowUserConfigDetails}>
                            {user['name']}
                          </td>
                          <td id={user['id']} onClick={handleShowUserConfigDetails}>
                            App de Vendedor
                          </td>
                          <td id={user['id']} onClick={handleShowUserConfigDetails}>
                            {user['email']}
                          </td>
                          <td id={user['id']} onClick={handleShowUserConfigDetails}>
                            {user['active'] ? 'Si' : 'No'}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>No hubo resultados</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
        <div className="container">
          <div className="row">
            <div className="center-block" style={{ marginLeft: 70 }}>
              <PaginationData.Provider value={paginationParams}>
                <UserPagination getCurrentPage={getCurrentPage} totalCount={totalCount} />
              </PaginationData.Provider>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ListUserConfig
