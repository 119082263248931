import React, { Component } from 'react'
import cookie from 'react-cookies'
import { DISCARD_CHANGES_CONFIRMATION } from '../constants/appMessages'
import history from '../helpers/history'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

export default class CreateNewClientView extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isFetching: true,
      failedCreation: false,
      data: null,
      failedCreationInfo: '',
      client_group_id: '',
      sales_channel_id: '',
      payment_type_id: '',
      credit_term_id: '',
      province_id: '',
      district_id: '',
      town_id: '',
      price_list_id: '',
      db_ref: '',
      active: true,
      taxpayer_id: '',
      taxpayer_verificator_digit: '',
      business_id: 1,
      business_name: '',
      commercial_name: '',
      customer_name_db_ref: '',
      credit_limit: '',
      legal_representative: '',
      billing_contact_name: '',
      billing_contact_email: '',
      billing_contact_phone: '',
      contact_name: '',
      contact_email: '',
      contact_phone: '',
      city: '',
      street: '',
      location_description: '',
      geographical_latitude: '',
      geographical_longitud: ''
    }
  }

  componentDidMount() {
    this.fetchDataToCreate()
  }

  fetchDataToCreate = () => {
    fetch(BACKEND_URL + '/businesses/1/clients/new', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      }
    })
      .then((response) => response.json())
      .then((data) => this.setState({ data: data, isFetching: false }))
      .catch((e) => console.log(e))
  }

  handleCreateClientSubmit = (event) => {
    event.preventDefault()
    if (!this.state.isFetching) {
      fetch(BACKEND_URL + '/clients', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        },
        body: JSON.stringify({
          client_group_id: this.state.client_group_id,
          sales_channel_id: this.state.sales_channel_id,
          payment_type_id: this.state.payment_type_id,
          credit_term_id: this.state.credit_term_id,
          province_id: this.state.province_id,
          district_id: this.state.district_id,
          town_id: this.state.town_id,
          price_list_id: this.state.price_list_id,
          db_ref: this.state.db_ref,
          active: this.state.active,
          taxpayer_id: this.state.taxpayer_id,
          taxpayer_verificator_digit: this.state.taxpayer_verificator_digit,
          business_name: this.state.business_name,
          business_id: this.state.business_id,
          commercial_name: this.state.commercial_name,
          customer_name_db_ref: this.state.customer_name_db_ref,
          credit_limit: this.state.credit_limit,
          legal_representative: this.state.legal_representative,
          billing_contact_name: this.state.billing_contact_name,
          billing_contact_email: this.state.billing_contact_email,
          billing_contact_phone: this.state.billing_contact_phone,
          contact_name: this.state.contact_name,
          contact_email: this.state.contact_email,
          contact_phone: this.state.contact_phone,
          city: this.state.city,
          street: this.state.street,
          location_description: this.state.location_description,
          geographical_latitude: this.state.geographical_latitude,
          geographical_longitud: this.state.geographical_longitud
        })
      })
        .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
        .then((obj) => this.evaluateResponse(obj))
        .catch((e) => console.log(e))
    }
  }

  evaluateResponse = (obj) => {
    switch (obj.status) {
      case 422:
        this.setState({
          failedCreationInfo: 'Verifique la informacion',
          failedCreation: true
        })
        break
      case 409:
        this.setState({
          failedCreationInfo:
            'No se puede crear cliente. Codigo ya esta en uso. Verifique los datos del cliente',
          failedCreation: true
        })
        break
      case 404:
        this.setState({
          failedCreationInfo: 'Servidor fuera de servicio',
          failedCreation: true
        })
        break
      case 500:
        this.setState({
          failedCreationInfo: 'Error interno en el sistema',
          failedCreation: true
        })
        break
      default:
        const data = obj.body
        this.setState({ failedCreation: false, failedCreationInfo: '' })
        history.push({
          pathname: '/clientes/' + data['id'] + '/detalles',
          params: { successCreateForNewClient: true }
        })
        break
    }
  }

  handleCancelCreate = (event) => {
    event.preventDefault()
    if (window.confirm(DISCARD_CHANGES_CONFIRMATION)) window.location.href = '/clientes'
  }

  handleChange = (event) => {
    const value = event.target.value
    const name = event.target.name
    this.setState({ [name]: value })
  }

  render() {
    return (
      <div className="main-view">
        {this.state.failedCreation === true && (
          <div className="alert alert-danger" role="alert">
            <strong>{this.state.failedCreationInfo}</strong>
          </div>
        )}
        <div>
          <div className="show-title">
            <h2>{this.props.title}</h2>
          </div>
        </div>
        <div className="index-table">
          {!this.state.isFetching && (
            <div className="show-area">
              <form onSubmit={this.handleCreateClientSubmit} className="create-new-salesman">
                <div className="inline-block full-width">
                  <div className="float-left create-new-salesman__form-description">
                    <div>Ingrese los siguientes datos para crear un cliente nuevo</div>
                  </div>
                  <div className="float-right">
                    <button onClick={this.handleCancelCreate} className="cancel-button">
                      Cancelar
                    </button>
                    <input className="save-button" value="Guardar" type="submit" />
                  </div>
                </div>
                <hr />
                <div className="red">
                  Por favor llene los campos obligatorios con * para poder proceder
                </div>
                <div className="data-row">
                  <div>Grupo de Cliente</div>
                  <select name="client_group_id" onChange={this.handleChange.bind(this)}>
                    <option value={null} />
                    {this.state.data['client_group'].map((obj) => (
                      <option key={obj.id} value={obj.id}>
                        {obj['description']}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="data-row">
                  <div>Canal de Ventas</div>
                  <select name="sales_channel_id" onChange={this.handleChange.bind(this)}>
                    <option value={null} />
                    {this.state.data['sales_channel'].map((obj) => (
                      <option key={obj.id} value={obj.id}>
                        {obj['description']}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="data-row">
                  <div>Tipo de Pago</div>
                  <select name="payment_type_id" onChange={this.handleChange.bind(this)}>
                    <option value={null} />
                    {this.state.data['payment_type'].map((obj) => (
                      <option key={obj.id} value={obj.id}>
                        {obj['description']}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="data-row">
                  <div>Plazo de Credito</div>
                  <select name="credit_term_id" onChange={this.handleChange.bind(this)}>
                    <option value={null} />
                    {this.state.data['credit_term'].map((obj) => (
                      <option key={obj.id} value={obj.id}>
                        {obj['description']}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="data-row">
                  <div>Provincia</div>
                  <select name="province_id" onChange={this.handleChange.bind(this)}>
                    <option value={null} />
                    {this.state.data['province'].map((obj) => (
                      <option key={obj.id} value={obj.id}>
                        {obj['name']}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="data-row">
                  <div>Distrito</div>
                  <select name="district_id" onChange={this.handleChange.bind(this)}>
                    <option value={null} />
                    {this.state.data['district'].map((obj) => (
                      <option key={obj.id} value={obj.id}>
                        {obj['name']}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="data-row">
                  <div>Corregimiento</div>
                  <select name="town_id" onChange={this.handleChange.bind(this)}>
                    <option value={null} />
                    {this.state.data['town'].map((obj) => (
                      <option key={obj.id} value={obj.id}>
                        {obj['name']}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="data-row">
                  <div>
                    Lista de Precio que le aplica<span className="red">*</span>
                  </div>
                  <select required name="price_list_id" onChange={this.handleChange.bind(this)}>
                    <option value={null} />
                    {this.state.data['price_list'].map((obj) => (
                      <option key={obj.id} value={obj.id}>
                        {obj['description']}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="data-row">
                  <div>
                    Codigo cliente<span className="red">*</span>
                  </div>
                  <input
                    type="text"
                    name="db_ref"
                    onChange={this.handleChange.bind(this)}
                    value={this.state.db_ref}
                    className="create-new-client__input"
                    required
                  />
                </div>
                <div className="data-row">
                  <div>Activo</div>
                  <select name="active" onChange={this.handleChange.bind(this)}>
                    <option value="true">Si</option>
                    <option value="false">No</option>
                  </select>
                </div>
                <div className="data-row">
                  <div>Ruc</div>
                  <input
                    type="text"
                    onChange={this.handleChange.bind(this)}
                    name="taxpayer_id"
                    value={this.state.taxpayer_id}
                    className="create-new-client__input"
                  />
                </div>
                <div className="data-row">
                  <div>DV</div>
                  <input
                    type="text"
                    onChange={this.handleChange.bind(this)}
                    name="taxpayer_verificator_digit"
                    value={this.state.taxpayer_verificator_digit}
                    className="create-new-client__input"
                  />
                </div>
                <div className="data-row">
                  <div>Razon Social</div>
                  <input
                    type="text"
                    onChange={this.handleChange.bind(this)}
                    name="business_name"
                    value={this.state.business_name}
                    className="create-new-client__input"
                  />
                </div>
                <div className="data-row">
                  <div>Nombre Comercial</div>
                  <input
                    type="text"
                    onChange={this.handleChange.bind(this)}
                    name="commercial_name"
                    value={this.state.commercial_name}
                    className="create-new-client__input"
                  />
                </div>
                <div className="data-row">
                  <div>
                    Nombre de cliente en sistema<span className="red">*</span>
                  </div>
                  <input
                    type="text"
                    onChange={this.handleChange.bind(this)}
                    name="customer_name_db_ref"
                    value={this.state.customer_name_db_ref}
                    className="create-new-client__input"
                    required
                  />
                </div>
                <div className="data-row">
                  <div>Monto de Credito</div>
                  <input
                    min="0"
                    max="999999"
                    type="number"
                    onChange={this.handleChange.bind(this)}
                    name="credit_limit"
                    value={this.state.credit_limit}
                    className="create-new-client__input"
                  />
                </div>
                <div className="data-row">
                  <div>Representante Legal</div>
                  <input
                    type="text"
                    onChange={this.handleChange.bind(this)}
                    name="legal_representative"
                    value={this.state.legal_representative}
                    className="create-new-client__input"
                  />
                </div>
                <div className="data-row">
                  <div>Persona de contacto para cobro de</div>
                  <input
                    type="text"
                    onChange={this.handleChange.bind(this)}
                    name="billing_contact_name"
                    value={this.state.billing_contact_name}
                    className="create-new-client__input"
                  />
                </div>
                <div className="data-row">
                  <div>Pago: correo electronico</div>
                  <input
                    type="text"
                    onChange={this.handleChange.bind(this)}
                    name="billing_contact_email"
                    value={this.state.billing_contact_email}
                    className="create-new-client__input"
                  />
                </div>
                <div className="data-row">
                  <div>Pago: Telefono</div>
                  <input
                    type="text"
                    onChange={this.handleChange.bind(this)}
                    name="billing_contact_phone"
                    value={this.state.billing_contact_phone}
                    className="create-new-client__input"
                  />
                </div>
                <div className="data-row">
                  <div>Persona de contacto</div>
                  <input
                    type="text"
                    onChange={this.handleChange.bind(this)}
                    name="contact_name"
                    value={this.state.contact_name}
                    className="create-new-client__input"
                  />
                </div>
                <div className="data-row">
                  <div>Correo electronico</div>
                  <input
                    type="text"
                    onChange={this.handleChange.bind(this)}
                    name="contact_email"
                    value={this.state.contact_email}
                    className="create-new-client__input"
                  />
                </div>
                <div className="data-row">
                  <div>Telefono</div>
                  <input
                    type="text"
                    onChange={this.handleChange.bind(this)}
                    name="contact_phone"
                    value={this.state.contact_phone}
                    className="create-new-client__input"
                  />
                </div>
                <div className="data-row">
                  <div>Ciudad</div>
                  <input
                    type="text"
                    onChange={this.handleChange.bind(this)}
                    name="city"
                    value={this.state.city}
                    className="create-new-client__input"
                  />
                </div>
                <div className="data-row">
                  <div>Calle</div>
                  <input
                    type="text"
                    onChange={this.handleChange.bind(this)}
                    name="street"
                    value={this.state.street}
                    className="create-new-client__input"
                  />
                </div>
                <div className="data-row">
                  <div>Guia de Ubicacion</div>
                  <input
                    type="text"
                    onChange={this.handleChange.bind(this)}
                    name="location_description"
                    value={this.state.location_description}
                    className="create-new-client__input"
                  />
                </div>
                <div className="data-row">
                  <div>Coordenadas en grados decimales (latitud)</div>
                  <input
                    type="text"
                    onChange={this.handleChange.bind(this)}
                    name="geographical_latitude"
                    value={this.state.geographical_latitude}
                    className="create-new-client__input"
                  />
                </div>
                <div className="data-row">
                  <div>Coordenadas en grados decimales (longitud)</div>
                  <input
                    type="text"
                    onChange={this.handleChange.bind(this)}
                    name="geographical_longitud"
                    value={this.state.geographical_longitud}
                    className="create-new-client__input"
                  />
                </div>
                <div className="data-row hidden">
                  <div>Ruta asignada</div>
                  <input
                    type="text"
                    onChange={this.handleChange.bind(this)}
                    name="route_id"
                    value={this.state.route_id}
                  />
                </div>
              </form>
            </div>
          )}
          {this.state.isFetching === true && (
            <div className="scroll-section">
              <p>Obteniendo datos</p>
            </div>
          )}
        </div>
      </div>
    )
  }
}
