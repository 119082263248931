import React from 'react'
import NavBar from '../layout/NavBar'
import TopBar from '../layout/TopBar'
import ShowOrderView from '../order/ShowOrderView'
import cookie from 'react-cookies'
import { DispatchConsumer, DispatchProvider } from './context/OrderContext'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

export default class ShowOrderApp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      searchPlaceholder: 'Buscar pedidos por código o nombre de cliente',
      id: window.location.href.split('/')[window.location.href.split('/').length - 1],
      isFetching: true,
      query: '',
      details: null,
      defaultOrderUuid: null,
      defaultOrderDetails: null
    }
  }

  componentDidMount() {
    this.fetchTransactionDetails()
  }

  fetchTransactionDetails = () => {
    this.setState({ isFetching: true })
    fetch(BACKEND_URL + '/transactions/' + this.props.match.params.id, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      }
    })
      .then((response) => {
        if (response.status === 401) {
          window.location = '/'
        }
        return response.json()
      })
      .then((data) =>
        this.setState(
          { details: data, defaultOrderUuid: data['orders'][0] },
          this.fetchDefaultOrderDetails
        )
      )
      .catch((e) => console.log(e))
  }

  fetchDefaultOrderDetails = () => {
    fetch(BACKEND_URL + '/orders/' + this.state.defaultOrderUuid, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      }
    })
      .then((response) => {
        if (response.status === 401) {
          window.location = '/'
        }
        return response.json()
      })
      .then((data) => this.setState({ defaultOrderDetails: data, isFetching: false }))
      .catch((e) => console.log(e))
  }

  handleSearch = (event, isEditing) => {
    // search action occurs after ENTER is pressed
    if (event.keyCode === 13) {
      // to disbale default submit form
      event.preventDefault()
      const query = event.target.value
      if (query !== '') {
        if (isEditing) {
          const val = window.confirm(
            'Esta seguro que quiere continuar? Los cambios editados no seran guardados'
          )
          if (val === true) {
            // redirecting to index accroding to clicked
            window.location = '/pedidos?query=' + query
          }
        } else {
          window.location = '/pedidos?query=' + query
        }
        event.target.value = ''
      }
    }
  }

  render() {
    return (
      <div>
        {this.state.isFetching === false ? (
          <div>
            <DispatchProvider
              componentState={this.props.match.params}
              details={this.state.details}
              orderDetails={this.state.orderDetails}
              defaultOrderDetails={this.state.defaultOrderDetails}
            >
              <DispatchConsumer>
                {({ isEditing }) => (
                  <div>
                    <div>
                      <div onKeyDown={(e) => this.handleSearch(e, isEditing)}>
                        <TopBar
                          searchPlaceholder={this.state.searchPlaceholder}
                          userName={this.state.userName}
                        />
                      </div>
                      <div>
                        <ShowOrderView
                          details={this.state.details}
                          position={this.props.match.params.id}
                          defaultOrderDetails={this.state.defaultOrderDetails}
                        />
                      </div>
                    </div>
                    <div>
                      <NavBar activeItem="Pedidos" isEditing={isEditing} />
                    </div>
                  </div>
                )}
              </DispatchConsumer>
            </DispatchProvider>
          </div>
        ) : (
          <div>
            <TopBar
              searchPlaceholder={this.state.searchPlaceholder}
              userName={this.state.userName}
            />
            <div className="main-view">
              <div className="show-title">
                <p>Obteniendo datos ....</p>
              </div>
            </div>
            <div>
              <NavBar activeItem="Pedidos" isEditing={false} />
            </div>
          </div>
        )}
      </div>
    )
  }
}
