import React, { useContext } from 'react'
import ProductStepProgressBar from '../component/ProductStepProgressBar'
import CreateProductDetailsStep from '../steps/CreateProductDetailsStep'
import CreateProductPriceListStep from '../steps/CreateProductPriceListStep'
import { SubmitCreateProductContext } from '../context/SubmitCreateProductProvider'
import NavBar from '../../layout/NavBar'

const CreateProductStep = (props) => {
  const { failedCreationInfo } = useContext(SubmitCreateProductContext)

  const step1Content = <CreateProductDetailsStep />
  const step2Content = <CreateProductPriceListStep />

  function onFormSubmit() {}

  return (
    <>
      <div>
        <div className="main-view">
          {failedCreationInfo !== null && (
            <div className="alert alert-danger" role="alert">
              <strong>{failedCreationInfo}</strong>
            </div>
          )}
          <div className="main-show-header">
            <h2>Crear Producto</h2>
          </div>
          <div>
            <div className="index-table">
              <div className="show-area-tab">
                <ProductStepProgressBar
                  startingStep={0}
                  onSubmit={onFormSubmit}
                  steps={[
                    {
                      label: 'Información',
                      name: 'Información',
                      content: step1Content
                    },
                    {
                      label: 'Listas de precio',
                      name: 'Listas de precio',
                      content: step2Content
                    }
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <NavBar activeItem="Productos" isEditing={true} />
        </div>
        )}
      </div>
    </>
  )
}
export default CreateProductStep
