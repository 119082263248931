import React from 'react'
import ReactDOM from 'react-dom'
import Pagination from '../layout/Pagination'
import Modal from 'react-awesome-modal'
import CreateProductModal from './CreateProductModal'

export default class ProductIndexView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isModalVisible: false
    }
  }

  showCreateProductPage() {
    window.location = '/crear_producto'
  }

  closeModal() {
    this.setState({
      isModalVisible: false
    })
  }

  showModel = (event) => {
    event.preventDefault()
    const id = event.target.id
    window.location = '/productos/' + id + '/detalles'
  }

  render() {
    const data = this.props.data

    if (this.props.isFetching) {
      return (
        <div className="main-view">
          <div className="index-header">
            <br />
            <p>Obteniendo datos ....</p>
          </div>
        </div>
      )
    } else {
      return (
        <div className="main-view">
          <div className="index-header">
            <h2>{this.props.title}</h2>
            <div className="index-buttons">
              <button onClick={() => this.showCreateProductPage()}>Crear producto nuevo</button>
            </div>
          </div>
          <div className="index-table">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>CÓDIGO</th>
                  <th>NOMBRE DEL PRODUCTO</th>
                  <th>MARCA</th>
                </tr>
              </thead>
              <tbody>
                {data.length > 0 &&
                  data.map((model, index) => (
                    <tr className="cursor-pointer" key={index}>
                      <td id={model['id']} onClick={this.showModel}>
                        {model['db_ref']}
                      </td>
                      <td id={model['id']} onClick={this.showModel}>
                        {model['name']}
                      </td>
                      {model['brand'] && (
                        <td id={model['id']} onClick={this.showModel}>
                          {model['brand']['description']}
                        </td>
                      )}
                      {!model['brand'] && <td id={model['id']} onClick={this.showModel}></td>}
                    </tr>
                  ))}
                {data.length === 0 && (
                  <tr>
                    <td>No hubo resultados</td>
                  </tr>
                )}
              </tbody>
            </table>
            <nav>
              {this.props.total_pages > 1 && (
                <Pagination
                  current_page={this.props.current_page}
                  total_pages={this.props.total_pages}
                />
              )}
            </nav>
          </div>
        </div>
      )
    }
  }
}
