import React from 'react'
import cookie from 'react-cookies'
import PriceScaleRow from './PriceScaleRow'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faDollarSign } from '@fortawesome/free-solid-svg-icons'
import history from '../helpers/history'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

export default class CreatePromoView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      failedCreation: false,
      failedCreationMessage: '',
      clientOptions: [],
      priceListOptions: [],
      productOptions: [],
      products_selected: [],
      promo_type: '',
      start_time: '',
      end_time: '',
      business_id: 1,
      applies_to_type: '',
      applies_to: [],
      base_product_ids: [],
      bonus_product_ids: [],
      base_quantity: 0,
      bonus_quantity: 0,
      price_reduction_products: [],
      scale_prices: [{ min: '1', max: 'INFINITY', price: '' }],
      scalePricesErrorMessage: ''
    }
  }

  handleChange = (event) => {
    event.preventDefault()
    const obj = {}
    obj[event.target.name] = event.target.value
    // when these values are reset product selected
    // should be reset in order to hide and empty table
    if (event.target.name === 'applies_to_type' || event.target.name === 'promo_type') {
      if (event.target.name === 'applies_to_type') {
        obj['products_selected'] = []
        obj['price_reduction_products'] = []
        obj['scale_prices'] = [{ min: '1', max: 'INFINITY', price: '' }]
        console.log('OBJ1', obj)
        this.setState(obj)
      } else {
        obj['products_selected'] = []
        obj['price_reduction_products'] = []
        obj['applies_to_type'] = null
        obj['applies_to'] = []
        console.log('OBJ2', obj)
        this.setState(obj)
      }
    } else {
      console.log('OBJ3', obj)
      this.setState(obj)
    }
  }

  handlePriceReductionInputChange = (event) => {
    const price = event.target.value
    const row = event.target.id
    const list = this.state.price_reduction_products
    list[row]['price'] = price
    this.setState({ price_reduction_products: list })
  }

  // in order to disable submit event triggered
  // by pressing the enter key in input field
  onKeyPress = (event) => {
    if (event.which === 13 /* Enter */) {
      event.preventDefault()
    }
  }

  searchForClient = (event) => {
    this.resetProductSelected()
    // to disbale submit form
    event.preventDefault()
    const query = event.target.value
    // will search after typing 3 or more characters
    if (query.length > 1) {
      fetch(BACKEND_URL + '/businesses/1/clients?q=' + query, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      })
        .then((response) => response.json())
        .then((data) => this.setState({ clientOptions: data['table'] }))
        .catch((e) => console.log(e))
    }
    const targetValue = event.target.value
    const element = document.getElementsByName(targetValue)[0]
    if (element) {
      const client_id = element.innerText
      if (client_id) {
        this.setState({ applies_to: [client_id] })
      }
    }
  }

  searchForPriceList = (event) => {
    this.resetProductSelected()
    // to disbale submit form
    event.preventDefault()
    const query = event.target.value
    // will search after typing 3 or more characters
    if (query.length > 1) {
      fetch(BACKEND_URL + '/businesses/1/price_lists?q=' + query, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      })
        .then((response) => response.json())
        .then((data) => this.setState({ priceListOptions: data['table'] }))
        .catch((e) => console.log(e))
    }
    var targetValue = event.target.value
    const element = document.getElementsByName(targetValue)[0]
    if (element) {
      const price_list_id = element.innerText
      if (price_list_id) {
        this.setState({ applies_to: [price_list_id] })
      }
    }
  }

  filterProducts = (data) => {
    var selected_product_ids = this.state.products_selected.map((obj) => obj.id)
    var not_selected_products = data.filter((obj) => selected_product_ids.indexOf(obj.id) < 0)
    this.setState({ productOptions: not_selected_products })
  }

  fetchProducts = (event) => {
    // to disbale submit form
    event.preventDefault()
    const query = event.target.value
    // will search after typing 3 or more characters
    if (query.length > 2) {
      if (this.state.applies_to_type === 'price_list') {
        fetch(
          BACKEND_URL +
            '/price_lists/' +
            this.state.applies_to[0] +
            '/product_pricelist?q=' +
            query,
          {
            method: 'GET',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + cookie.load('token')
            }
          }
        )
          .then((response) => response.json())
          .then((data) => this.filterProducts(data['table']))
          .catch((e) => console.log(e))
      } else if (this.state.applies_to_type === 'client') {
        fetch(
          BACKEND_URL + '/clients/' + this.state.applies_to[0] + '/product_pricelist?q=' + query,
          {
            method: 'GET',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + cookie.load('token')
            }
          }
        )
          .then((response) => response.json())
          .then((data) => this.filterProducts(data['table']))
          .catch((e) => console.log(e))
      }
    }
    var targetValue = event.target.value
    const element = document.getElementsByName(targetValue)[0]
    if (element) {
      const product_id = element.innerText
      if (product_id) {
        var selectedObj = this.state.productOptions.filter(
          (obj) => parseInt(product_id) === obj.id
        )[0]
        if (selectedObj) {
          const new_obj = this.state.products_selected
          new_obj.push(selectedObj)
          this.setState({ products_selected: new_obj }, this.setParamsToPostToServer)
          event.target.value = ''
        }
      }
    }
  }

  setParamsToPostToServer = (event) => {
    if (this.state.promo_type === 'bonus_product') {
      this.setState({
        base_product_ids: [this.state.products_selected[0].id],
        bonus_product_ids: [this.state.products_selected[0].id]
      })
    } else if (this.state.promo_type === 'price_reduction') {
      // will add the last item that was added to products_selected
      const list = this.state.price_reduction_products
      list.push({
        product_id: this.state.products_selected[this.state.products_selected.length - 1]['id']
      })
      this.setState({ price_reduction_products: list })
    }
  }

  resetProductSelected = (event) => {
    this.setState({ products_selected: [] })
    this.setState({ price_reduction_products: [] })
    this.setState({ scale_prices: [{ min: '1', max: 'INFINITY', price: '' }] })
    this.setState({ scalePricesErrorMessage: '' })
  }

  deleteProductRowFromTable = (event) => {
    const index = event.target.id
    const list1 = this.state.products_selected
    const list2 = this.state.price_reduction_products
    list1.splice(index, 1)
    list2.splice(index, 1)
    this.setState({ products_selected: list1 })
    this.setState({ price_reduction_products: list2 })
  }

  cancelCreate = (event) => {
    event.preventDefault()
    const val = window.confirm(
      '¿Esta seguro que quiere continuar? Los cambios editados no seran guardados'
    )
    if (val) {
      window.location = '/promociones'
    }
  }

  sendCreateParams = (event) => {
    event.preventDefault()
    var missingPrice = this.state.scale_prices.filter((obj) => obj.price === '')
    var notConsecutive = this.state.scale_prices.filter(
      (obj) => parseInt(obj.max) <= parseInt(obj.min)
    )
    if (this.state.promo_type === 'scale_prices_reduction' && notConsecutive.length > 0) {
      this.setState({ scalePricesErrorMessage: 'Las escalas deben ser consecutivas' })
      this.setState({
        failedCreation: true,
        failedCreationMessage: 'Hay un error en el formulario, verifique la informacion.'
      })
    } else if (this.state.promo_type === 'scale_prices_reduction' && missingPrice.length > 0) {
      this.setState({
        scalePricesErrorMessage: 'Por favor agregue un precio de oferta para poder agregar escala'
      })
      this.setState({
        failedCreation: true,
        failedCreationMessage: 'Hay un error en el formulario, verifique la informacion.'
      })
    } else {
      var body = {
        start_time: this.state.start_time,
        end_time: this.state.end_time,
        business_id: 1,
        applies_to_type: this.state.applies_to_type,
        applies_to: this.state.applies_to
      }
      if (this.state.promo_type === 'bonus_product') {
        body['products'] = [
          {
            base_product_ids: this.state.bonus_product_ids,
            base_quantity: this.state.base_quantity,
            bonus_product_ids: this.state.bonus_product_ids,
            bonus_quantity: this.state.bonus_quantity
          }
        ]
        this.postCreateBonusProductPromo(body)
      } else if (this.state.promo_type === 'price_reduction') {
        body['products'] = this.state.price_reduction_products
        this.postCreatePriceReductionPromo(body)
      } else if (this.state.promo_type === 'scale_prices_reduction') {
        body['products'] = [this.state.products_selected[0].id]
        const hash = {}
        this.state.scale_prices.map((data) => (hash[data.min + '-' + data.max] = data.price))
        body['scale_prices_hash'] = hash
        this.postCreateScalePricesReductionPromo(body)
      }
    }
  }

  postCreateScalePricesReductionPromo = (body) => {
    fetch(BACKEND_URL + '/scale_prices_reduction_promos', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      },
      body: JSON.stringify(body)
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => this.evaluateResponse(obj))
      .catch((e) => console.log(e))
  }

  postCreatePriceReductionPromo = (body) => {
    fetch(BACKEND_URL + '/price_reduction_promos', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      },
      body: JSON.stringify(body)
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => this.evaluateResponse(obj))
      .catch((e) => console.log(e))
  }

  postCreateBonusProductPromo = (body) => {
    fetch(BACKEND_URL + '/bonus_product_promos', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      },
      body: JSON.stringify(body)
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => this.evaluateResponse(obj))
      .catch((e) => console.log(e))
  }

  evaluateResponse = (obj) => {
    // evaluate responses for status 422 or 500
    if (obj.status === 422) {
      this.setState({
        failedCreation: true,
        failedCreationMessage: Object.keys(obj.body).map((k) => obj.body[k])
      })
    } else if (obj.status === 500) {
      this.setState({ failedCreation: true, failedCreationMessage: obj.body.exception })
    } else if (obj.status === 404) {
      this.setState({ failedCreation: true, failedCreationMessage: obj.body.error })
    } else {
      history.push({
        pathname: '/promociones',
        params: { successCreateForNewPromo: true }
      })
    }
  }

  getMonth = () => {
    var m = new Date().getMonth() + 1
    if (m < 10) {
      return '0' + m
    } else {
      return m
    }
  }

  getDay = () => {
    var d = new Date().getDate()
    if (d < 10) {
      return '0' + d
    } else {
      return d
    }
  }

  addNewScalePrice = () => {
    var missingMax = this.state.scale_prices.filter((obj) => obj.max === 'INFINITY')
    var missingPrice = this.state.scale_prices.filter((obj) => obj.price === '')
    var notConsecutive = this.state.scale_prices.filter(
      (obj) => parseInt(obj.max) <= parseInt(obj.min)
    )
    if (notConsecutive.length > 0) {
      this.setState({ scalePricesErrorMessage: 'Las escalas deben ser consecutivas' })
    } else if (missingMax.length > 0) {
      this.setState({
        scalePricesErrorMessage: 'Por favor agregue un máximo para poder agregar escala'
      })
    } else if (missingPrice.length > 0) {
      this.setState({
        scalePricesErrorMessage: 'Por favor agregue un precio de oferta para poder agregar escala'
      })
    } else {
      var lastMax = this.state.scale_prices[this.state.scale_prices.length - 1]['max']
      const obj = { min: String(parseInt(lastMax) + 1), max: 'INFINITY', price: '' }
      this.setState({ scalePricesErrorMessage: '' })
      this.setState({ scale_prices: [...this.state.scale_prices, obj] })
    }
  }

  deleteScalePrice(row) {
    const a = this.state.scale_prices
    // that means there is a scale range below of this one
    if (typeof a[row + 1] !== 'undefined') {
      a[row + 1]['min'] = a[row]['min']
    }
    a.splice(row, 1)
    this.setState({ scale_prices: a })
  }

  updateScalePrice(row, event) {
    var targetName = event.target.name
    var targetValue = event.target.value
    var list = this.state.scale_prices
    var obj = list[row]
    obj[targetName] = targetValue
    list[row] = obj
    this.setState({ scale_prices: list, scalePricesErrorMessage: '' })
  }

  validateConsecutiveScales(row, event) {
    var notConsecutive = this.state.scale_prices.filter(
      (obj) => parseInt(obj.max) <= parseInt(obj.min)
    )
    if (notConsecutive.length > 0) {
      this.setState({ scalePricesErrorMessage: 'Las escalas deben ser consecutivas' })
    }
  }

  render() {
    return (
      <div className="main-view">
        {this.state.failedCreation === true && (
          <div className="alert alert-danger" role="alert">
            <strong>{this.state.failedCreationMessage}</strong>
          </div>
        )}
        <div>
          <div className="show-title">
            <h2>Promoción nueva</h2>
          </div>
          <div className="index-table">
            <div className="show-area">
              <div className="create-promo-wrapper">
                <form onSubmit={this.sendCreateParams.bind(this)}>
                  <div className="inline-block full-width">
                    <div className="float-left">
                      <h4>Ingrese los siguientes datos para crear una promoción nueva</h4>
                    </div>
                    <div className="float-right">
                      <button onClick={this.cancelCreate} className="cancel-button">
                        Cancelar
                      </button>
                      <input className="save-button" value="Guardar" type="submit" />
                    </div>
                  </div>
                  <hr />
                  <div className="promo-form-section mt-33">
                    <div className="title-promos-form">
                      <b>PASO 1: Escoger tipo de promoción</b>
                    </div>
                    <hr className="hr-promos-form" />
                    <select
                      onChange={this.handleChange}
                      className="select-promo-type"
                      name="promo_type"
                      required
                    >
                      <option value="" hidden>
                        Escoger una opcion
                      </option>
                      <option value="bonus_product">Bonificación de una referencia</option>
                      <option value="price_reduction">Precio rebajado</option>
                      <option value="scale_prices_reduction">Precio rebajado por escalas</option>
                    </select>
                  </div>

                  <div className="promo-form-section">
                    <div className="title-promos-form">
                      <b>
                        PASO 2: Escoger si la promoción le aplica a un cliente individual o lista de
                        precio
                      </b>
                    </div>
                    <hr className="hr-promos-form" />
                    {this.state.promo_type === '' && (
                      <div>
                        <select
                          className="select-applies_to_type inactive"
                          name="applies_to_type"
                          required
                          disabled
                        >
                          <option value="" hidden>
                            Escoger una opcion
                          </option>
                        </select>
                      </div>
                    )}
                    {this.state.promo_type === 'bonus_product' && (
                      <div>
                        <select
                          onChange={this.handleChange}
                          className="select-applies_to_type"
                          name="applies_to_type"
                          required
                        >
                          <option value="" hidden>
                            Escoger una opcion
                          </option>
                          <option value="client">Cliente individual</option>
                          <option value="price_list">Lista de precio</option>
                        </select>
                      </div>
                    )}
                    {this.state.promo_type === 'price_reduction' && (
                      <div>
                        <select
                          onChange={this.handleChange}
                          className="select-applies_to_type"
                          name="applies_to_type"
                          required
                        >
                          <option value="" hidden>
                            Escoger una opcion
                          </option>
                          <option value="client">Cliente individual</option>
                          <option value="price_list">Lista de precio</option>
                        </select>
                      </div>
                    )}
                    {this.state.promo_type === 'scale_prices_reduction' && (
                      <div>
                        <select
                          onChange={this.handleChange}
                          className="select-applies_to_type"
                          name="applies_to_type"
                          required
                        >
                          <option value="" hidden>
                            Escoger una opcion
                          </option>
                          <option value="client">Cliente individual</option>
                          <option value="price_list">Lista de precio</option>
                        </select>
                      </div>
                    )}
                  </div>

                  {this.state.applies_to_type === 'client' && (
                    <div className="promo-form-section">
                      <div className="title-promos-form">
                        <b>2.1: Escoger el cliente a quien le aplica la promoción</b>
                      </div>
                      <div className="mt-16">Cliente</div>
                      <input
                        autoComplete="off"
                        onChange={this.searchForClient.bind(this)}
                        onKeyPress={this.onKeyPress}
                        className="search-input-border"
                        placeholder="Escribir nombre o codigo del cliente"
                        name="applies_to"
                        list="all-clients"
                        required
                      />
                      <datalist id="all-clients">
                        {/*<option key={obj.id} id={obj.id}>{obj.db_ref + " - " +  obj.name}</option> */}
                        {this.state.clientOptions.map((obj) => (
                          <option
                            key={obj.id}
                            id={obj.id}
                            name={obj.db_ref + ' - ' + obj.name}
                            value={obj.db_ref + ' - ' + obj.name}
                          >
                            {obj.id}
                          </option>
                        ))}
                      </datalist>
                      <span className="icon-search">
                        <FontAwesomeIcon className="icon-search" icon={faSearch} />
                      </span>
                    </div>
                  )}

                  {this.state.applies_to_type === 'price_list' && (
                    <div className="promo-form-section">
                      <div className="title-promos-form">
                        <b>PASO 2.1: Escoger la lista de precio a quien le aplica la promoción</b>
                      </div>
                      <div className="mt-16">Lista de Precio</div>
                      <input
                        autoComplete="off"
                        onChange={this.searchForPriceList}
                        className="search-input-border"
                        placeholder="Escribir nombre o codigo de la lista de precio"
                        name="applies_to"
                        list="all-price-lists"
                        onKeyPress={this.onKeyPress}
                        required
                      />
                      <datalist id="all-price-lists">
                        {this.state.priceListOptions.map((obj) => (
                          <option
                            key={obj.id}
                            id={obj.id}
                            name={obj.db_ref + ' - ' + obj.name}
                            value={obj.db_ref + ' - ' + obj.name}
                          >
                            {obj.id}
                          </option>
                        ))}
                      </datalist>
                      <span className="icon-search">
                        <FontAwesomeIcon className="icon-search" icon={faSearch} />
                      </span>
                    </div>
                  )}

                  <div className="promo-form-section">
                    <div className="title-promos-form">
                      <b>PASO 3: Escoger el producto al que le aplica esta promoción</b>
                    </div>
                    <hr className="hr-promos-form" />
                    <div>Producto</div>
                    {this.state.applies_to.length === 0 && (
                      <input
                        autoComplete="off"
                        onChange={this.fetchProducts}
                        onKeyPress={this.onKeyPress}
                        className="search-input-border inactive"
                        list="all-products"
                        placeholder="Escribir nombre o codigo del producto"
                        required
                        disabled
                      />
                    )}
                    {this.state.applies_to.length === 0 && (
                      <span className="icon-search">
                        <FontAwesomeIcon className="icon-search inactive" icon={faSearch} />
                      </span>
                    )}

                    {this.state.applies_to.length !== 0 &&
                      this.state.products_selected.length === 0 && (
                        <input
                          autoComplete="off"
                          onChange={this.fetchProducts}
                          onKeyPress={this.onKeyPress}
                          className="search-input-border"
                          placeholder="Escribir nombre o codigo del producto"
                          list="all-products"
                          required
                        />
                      )}
                    {this.state.applies_to.length !== 0 &&
                      this.state.products_selected.length === 0 && (
                        <span className="icon-search">
                          <FontAwesomeIcon className="icon-search" icon={faSearch} />
                        </span>
                      )}

                    {this.state.applies_to.length !== 0 &&
                      this.state.products_selected.length > 0 &&
                      this.state.promo_type == 'price_reduction' && (
                        <input
                          autoComplete="off"
                          onChange={this.fetchProducts}
                          onKeyPress={this.onKeyPress}
                          className="search-input-border"
                          placeholder="Escribir nombre o codigo del producto"
                          list="all-products"
                        />
                      )}
                    {this.state.applies_to.length !== 0 &&
                      this.state.products_selected.length > 0 &&
                      this.state.promo_type === 'price_reduction' && (
                        <span className="icon-search">
                          <FontAwesomeIcon className="icon-search" icon={faSearch} />
                        </span>
                      )}

                    {this.state.applies_to.length !== 0 &&
                      this.state.products_selected.length > 0 &&
                      (this.state.promo_type === 'bonus_product' ||
                        this.state.promo_type === 'scale_prices_reduction') && (
                        <input
                          autoComplete="off"
                          onChange={this.fetchProducts}
                          list="all-products"
                          className="search-input-border inactive"
                          disabled
                          placeholder="Escribir nombre o codigo del producto"
                        />
                      )}

                    <datalist id="all-products">
                      {this.state.productOptions.map((obj, index) => (
                        <option
                          key={index}
                          id={obj.id}
                          name={obj.db_ref + ' - ' + obj.name}
                          value={obj.db_ref + ' - ' + obj.name}
                        >
                          {obj.id}
                        </option>
                      ))}
                    </datalist>
                  </div>

                  <div className="promo-form-section">
                    {this.state.products_selected.length > 0 &&
                      this.state.promo_type !== 'price_reduction' && (
                        <table className="table table-hover promos-product-table">
                          <thead>
                            <tr>
                              <th className="pl-40">CODIGO</th>
                              <th>NOMBRE DEL PRODUCTO</th>
                              <th>UNIDAD</th>
                              <th>PRECIO</th>
                              <th />
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="pl-40">{this.state.products_selected[0].db_ref}</td>
                              <td>{this.state.products_selected[0].name}</td>
                              <td>{this.state.products_selected[0].price_unit.description}</td>
                              <td>${Number(this.state.products_selected[0].price).toFixed(2)}</td>
                              <td
                                className="delete-selected-product"
                                onClick={this.resetProductSelected}
                              >
                                &times;
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      )}
                    {this.state.products_selected.length > 0 &&
                      this.state.promo_type == 'price_reduction' && (
                        <table className="table table-hover promos-product-table">
                          <thead>
                            <tr>
                              <th className="pl-40">CODIGO</th>
                              <th>NOMBRE DEL PRODUCTO</th>
                              <th>UNIDAD</th>
                              <th>PRECIO</th>
                              <th>PRECIO DE OFERTA</th>
                              <th />
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.products_selected.map((product, index) => (
                              <tr key={index}>
                                <td className="pl-40">{product.db_ref}</td>
                                <td>{product.name}</td>
                                <td>{product.price_unit.description}</td>
                                <td>${product.price}</td>
                                <td>
                                  <span className="icon-dollar">
                                    <FontAwesomeIcon icon={faDollarSign} />
                                  </span>
                                  <input
                                    id={index}
                                    type="number"
                                    min="0"
                                    step="0.000001"
                                    autoComplete="off"
                                    name="price"
                                    onChange={this.handlePriceReductionInputChange}
                                    className="price-input"
                                    placeholder={product.price}
                                    required
                                  />
                                </td>
                                <td
                                  id={index}
                                  className="delete-selected-product"
                                  onClick={this.deleteProductRowFromTable}
                                >
                                  &times;
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                  </div>

                  {this.state.products_selected.length > 0 &&
                    this.state.promo_type === 'bonus_product' && (
                      <div className="promo-form-section">
                        <div className="title-promos-form">
                          <b>3.1: Establece la regla de esta promoción</b>
                        </div>
                        <div className="mt-16 inline-block">
                          <div className="float-left">
                            <div> Base </div>
                            <input
                              min="0"
                              step="1.0"
                              type="number"
                              autoComplete="off"
                              name="base_quantity"
                              onChange={this.handleChange}
                              className="quantiy-input"
                              placeholder="0"
                              required
                            />
                            <span className="unit-span">
                              {this.state.products_selected[0].price_unit.description}
                            </span>
                            <span className="plus-span">+ </span>
                          </div>
                          <div className="float-right">
                            <div> Bonificación</div>
                            <input
                              min="0"
                              step="1.0"
                              type="number"
                              autoComplete="off"
                              name="bonus_quantity"
                              onChange={this.handleChange}
                              className="quantiy-input"
                              placeholder="0"
                              required
                            />
                            <span className="unit-span">
                              {this.state.products_selected[0].price_unit.description}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}

                  {this.state.products_selected.length > 0 &&
                    this.state.promo_type === 'scale_prices_reduction' && (
                      <div className="promo-form-section">
                        <div className="title-promos-form">
                          <b>3.1: Establece la regla de esta promoción</b>
                        </div>
                        <div className="mt-33">
                          {this.state.scale_prices.map((data, index) => (
                            <PriceScaleRow
                              unit={this.state.products_selected[0].price_unit.description}
                              row={index}
                              data={data}
                              key={index}
                              total={this.state.scale_prices.length}
                              delete={this.deleteScalePrice.bind(this)}
                              updateScalePrice={this.updateScalePrice.bind(this)}
                              validateConsecutiveScales={this.validateConsecutiveScales.bind(this)}
                            />
                          ))}
                        </div>
                        <div style={{ color: 'red', marginTop: '30px' }}>
                          {this.state.scalePricesErrorMessage}
                        </div>
                        <button
                          onClick={this.addNewScalePrice.bind(this)}
                          style={{
                            backgroundColor: '#597FA9',
                            color: 'white',
                            width: '144px',
                            height: '48px',
                            border: 'none',
                            borderRadius: '3px',
                            marginTop: '20px'
                          }}
                          type="button"
                        >
                          {' '}
                          Agregar escala{' '}
                        </button>
                      </div>
                    )}

                  <div className="promo-form-section-dates">
                    <div className="title-promos-form">
                      <b>PASO 4: Escoger fecha de inicio y fecha final de la promoción</b>
                    </div>
                    <hr className="hr-promos-form" />
                    <div className="inline-block">
                      <div className="float-left">
                        <label>Inicio</label>
                        <br />
                        <input
                          min={
                            new Date().getFullYear() + '-' + this.getMonth() + '-' + this.getDay()
                          }
                          onChange={this.handleChange}
                          className="start-date"
                          type="date"
                          name="start_time"
                          required
                        />
                        <span className="dates-separator">______</span>
                      </div>
                      <div className="float-right">
                        <label>Fin</label>
                        <br />
                        <input
                          min={this.state.start_time}
                          onChange={this.handleChange}
                          className="end-date"
                          type="date"
                          name="end_time"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
