import React from 'react'
import cookie from 'react-cookies'
import history from '../../helpers/history'

const { Provider, Consumer } = React.createContext()

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

class ClientProvider extends React.Component {
  state = {
    isFetching: true,
    isEditClient: false,
    clientDetailsData: null,
    clientEditDetailsData: '',
    editingClient: false,
    addingClient: false,
    failedCreation: false,
    failedCreationInfo: '',
    activeTab: 'Detalles',
    id: window.location.href.split('/')[window.location.href.split('/').length - 2],
    clientForm: {
      client_group_id: '',
      sales_channel_id: '',
      payment_type_id: '',
      credit_term_id: '',
      province_id: '',
      district_id: '',
      town_id: '',
      price_list_id: '',
      db_ref: '',
      active: '',
      taxpayer_id: '',
      taxpayer_verificator_digit: '',
      business_name: '',
      commercial_name: '',
      customer_name_db_ref: '',
      credit_limit: '',
      legal_representative: '',
      billing_contact_name: '',
      billing_contact_email: '',
      billing_contact_phone: '',
      contact_name: '',
      contact_email: '',
      contact_phone: '',
      city: '',
      street: '',
      location_description: '',
      geographical_latitude: '',
      geographical_longitud: ''
    }
  }

  componentDidMount() {
    this.fetchDetailsFromServer()
  }

  fetchDetailsFromServer = () => {
    this.setState({ isFetching: true })
    fetch(BACKEND_URL + '/clients/' + this.state.id, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      }
    })
      .then((response) => {
        if (response.status === 401) {
          window.location = '/'
        }
        return response.json()
      })
      .then((data) =>
        this.setState({ clientDetailsData: data, isFetching: false }, this.setDefaultParams)
      )
      .catch((e) => console.log(e))
    this.fetchDataForEdit()
  }

  setDefaultParams = () => {
    this.setState({
      clientForm: {
        client_group_id: this.state.clientDetailsData['client_group']
          ? this.state.clientDetailsData['client_group']['id']
          : '',
        sales_channel_id: this.state.clientDetailsData['sales_channel']
          ? this.state.clientDetailsData['sales_channel']['id']
          : '',
        payment_type_id: this.state.clientDetailsData['payment_type']
          ? this.state.clientDetailsData['payment_type']['id']
          : '',
        credit_term_id: this.state.clientDetailsData['credit_term']
          ? this.state.clientDetailsData['credit_term']['id']
          : '',
        province_id: this.state.clientDetailsData['province']
          ? this.state.clientDetailsData['province']['id']
          : '',
        district_id: this.state.clientDetailsData['district']
          ? this.state.clientDetailsData['district']['id']
          : '',
        town_id: this.state.clientDetailsData['town']
          ? this.state.clientDetailsData['town']['id']
          : '',
        price_list_id: this.state.clientDetailsData['price_list']
          ? this.state.clientDetailsData['price_list']['id']
          : '',
        db_ref: this.state.clientDetailsData['db_ref'],
        active: this.state.clientDetailsData['active'],
        taxpayer_id: this.state.clientDetailsData['taxpayer_id'],
        taxpayer_verificator_digit: this.state.clientDetailsData['taxpayer_verificator_digit'],
        business_name: this.state.clientDetailsData['business_name'],
        commercial_name: this.state.clientDetailsData['commercial_name'],
        customer_name_db_ref: this.state.clientDetailsData['customer_name_db_ref'],
        credit_limit: this.state.clientDetailsData['credit_limit'],
        legal_representative: this.state.clientDetailsData['legal_representative'],
        billing_contact_name: this.state.clientDetailsData['billing_contact_name'],
        billing_contact_email: this.state.clientDetailsData['billing_contact_email'],
        billing_contact_phone: this.state.clientDetailsData['billing_contact_phone'],
        contact_name: this.state.clientDetailsData['contact_name'],
        contact_email: this.state.clientDetailsData['contact_email'],
        contact_phone: this.state.clientDetailsData['contact_phone'],
        city: this.state.clientDetailsData['city'],
        street: this.state.clientDetailsData['street'],
        location_description: this.state.clientDetailsData['location_description'],
        geographical_latitude: this.state.clientDetailsData['geographical_latitude'],
        geographical_longitud: this.state.clientDetailsData['geographical_longitud']
      }
    })
  }

  fetchDataForEdit = () => {
    fetch(BACKEND_URL + '/businesses/1/clients/new', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      }
    })
      .then((response) => response.json())
      .then((data) => this.setState({ clientEditDetailsData: data }))
      .catch((e) => console.log(e))
  }

  createOrUpdateClient = (event) => {
    if (event.target.value === 'Actualizar cliente') {
      this.setState({ isEditClient: true, editingClient: true })
      this.passEditState(true)
    } else if (event.target.value === 'Cancelar') {
      this.setState({ isEditClient: false, editingClient: false })
      this.passEditState(false)
    }
  }

  passEditState = (val) => {
    this.setState({ isEditClient: val })
  }

  handleSubmitEditClient = (event) => {
    event.preventDefault()
    fetch(BACKEND_URL + '/clients/' + this.state.id, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      },
      body: JSON.stringify({
        client_group_id: this.state.clientForm.client_group_id,
        sales_channel_id: this.state.clientForm.sales_channel_id,
        payment_type_id: this.state.clientForm.payment_type_id,
        credit_term_id: this.state.clientForm.credit_term_id,
        province_id: this.state.clientForm.province_id,
        district_id: this.state.clientForm.district_id,
        town_id: this.state.clientForm.town_id,
        price_list_id: this.state.clientForm.price_list_id,
        db_ref: this.state.clientForm.db_ref,
        active: this.state.clientForm.active,
        taxpayer_id: this.state.clientForm.taxpayer_id,
        taxpayer_verificator_digit: this.state.clientForm.taxpayer_verificator_digit,
        business_name: this.state.clientForm.business_name,
        commercial_name: this.state.clientForm.commercial_name,
        customer_name_db_ref: this.state.clientForm.customer_name_db_ref,
        credit_limit: this.state.clientForm.credit_limit,
        legal_representative: this.state.clientForm.legal_representative,
        billing_contact_name: this.state.clientForm.billing_contact_name,
        billing_contact_email: this.state.clientForm.billing_contact_email,
        billing_contact_phone: this.state.clientForm.billing_contact_phone,
        contact_name: this.state.clientForm.contact_name,
        contact_email: this.state.clientForm.contact_email,
        contact_phone: this.state.clientForm.contact_phone,
        city: this.state.clientForm.city,
        street: this.state.clientForm.street,
        location_description: this.state.clientForm.location_description,
        geographical_latitude: this.state.clientForm.geographical_latitude,
        geographical_longitud: this.state.clientForm.geographical_longitud
      })
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => this.evaluateResponse(obj, false))
      .catch((e) => console.log(e))
  }

  evaluateResponse = (obj) => {
    switch (obj.status) {
      case 422:
        this.setState({
          failedCreation: true
        })
        break
      case 409:
        this.setState({
          failedCreationInfo:
            'No se puede crear cliente. Codigo ya esta en uso. Verifique los datos del cliente',
          failedCreation: true
        })
        break
      case 404:
        this.setState({
          failedCreationInfo: 'Servidor fuera de servicio',
          failedCreation: true
        })
        break
      case 500:
        this.setState({
          failedCreationInfo: 'Error interno en el sistema',
          failedCreation: true
        })
        break
      default:
        const data = obj.body
        window.location = '/clientes/' + data['id'] + '/detalles'
        break
    }
  }

  handleChange = (event) => {
    const obj = this.state.clientForm
    obj[event.target.name] = event.target.value
    this.setState({
      clientForm: obj
    })
  }

  render() {
    return (
      <Provider
        value={{
          clientData: this.state.clientDetailsData,
          clientEditDetailsData: this.state.clientEditDetailsData,
          isEditClient: this.state.isEditClient,
          isFetching: this.state.isFetching,
          failedCreation: this.state.failedCreation,
          failedCreationInfo: this.state.failedCreationInfo,
          handleChange: this.handleChange,
          editingClient: this.state.editingClient,
          handleSubmitEditClient: this.handleSubmitEditClient,
          activeTab: this.state.activeTab,
          passEditState: this.passEditState,
          handleClickButton: this.createOrUpdateClient
        }}
      >
        {this.props.children}
      </Provider>
    )
  }
}

export { ClientProvider, Consumer as ClientConsumer }
