import React, { useContext } from 'react'
import { GlobalContext } from '../../../global_context/GlobalContext'

const ConvertOrderInput = (props) => {
  const { variants } = useContext(GlobalContext)

  const handleDispatchState = () => {
    props.handleDispatchState()
  }
  const cancelOrder = () => {
    props.cancelOrder()
  }

  return (
    <div>
      {variants.shouldCreateOrder ? (
        <div>
          <input
            type="submit"
            onClick={cancelOrder}
            className="undo-button"
            value="Anular pedido"
          />
          <input
            className="save-button"
            onClick={handleDispatchState}
            value="Convertir a Despacho"
            type="submit"
          />
        </div>
      ) : (
        <div></div>
      )}
    </div>
  )
}

export default ConvertOrderInput
