import React from 'react'
import { ClientEcommerceConsumer } from '../context/ClientEcommerceContext'
import { Table } from 'react-bootstrap'
import ProductPriceListRow from '../../product/ProductPriceListRow'
import EcommerceUserRow from './EcommerceUserRow'

function IndexEcommerceView() {
  return (
    <ClientEcommerceConsumer>
      {({ eCommerceUserData }) => (
        <div>
          <Table striped hover>
            <thead>
              <tr>
                <th>ESTADO</th>
                <th>NOMBRE</th>
                <th>CORREO</th>
                <th>NUMERO DE CONTACTO</th>
                <th>FECHA DE ACTUALIZACION</th>
                <th className="text-align-center">ACCION</th>
              </tr>
            </thead>
            <tbody>
              {eCommerceUserData.length > 0 &&
                eCommerceUserData.map((obj) => <EcommerceUserRow key={obj.id} obj={obj} />)}
            </tbody>
          </Table>
          {eCommerceUserData.length === 0 && (
            <p className="mt-16 ml-16">No existen clientes ecommerce para este cliente</p>
          )}
        </div>
      )}
    </ClientEcommerceConsumer>
  )
}

export default IndexEcommerceView
