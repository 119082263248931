import { DispatchConsumer } from '../context/OrderContext'
import React from 'react'
import moment from 'moment'
import cookie from 'react-cookies'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

const DispatchDetails = (props) => {
  const getFormattedDate = (createdDate) => {
    const d = new Date(createdDate)
    return d.toLocaleString()
  }

  const cancelDelivery = () => {
    const response = window.confirm('¿Esta seguro que quiere anular el despacho?')
    if (response === true) {
      fetch(BACKEND_URL + '/deliveries/' + props.deliveryDetails.uuid, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      })
        .then((data) => (window.location = '/pedidos/' + props.transactionUuid + '/cancel'))
        .catch((e) => console.log(e))
    }
  }

  console.log('props', props)
  return (
    <DispatchConsumer>
      {({ handleUpdateDispatchState }) => (
        <div>
          {props.deliveryDetails !== null && (
            <div className="row show-product-header">
              <div className="col-sm-6">
                <h3 className="fs-18">Detalles de despacho</h3>
              </div>
              <div className="col-sm-6">
                {moment(props.deliveryDetails['delivery_date']).format('YYYY-MM-DD') >
                  moment().format('YYYY-MM-DD') &&
                  props.deliveryDetails.status === 'created' && (
                    <div className="show-buttons">
                      <input
                        type="submit"
                        onClick={cancelDelivery}
                        className="undo-button"
                        value="Anular Despacho"
                      />
                      <input
                        className="save-button"
                        onClick={handleUpdateDispatchState}
                        value="Actualizar Despacho"
                        type="submit"
                      />
                    </div>
                  )}
              </div>
            </div>
          )}
          <hr />
          {props.deliveryDetails !== null && (
            <div>
              {props.deliveryDetails['status'] === 'cancelled' && (
                <div className="anulado-label">ANULADO</div>
              )}
              <div className="show-product-header show-header">
                <div className="order-client-name">{props.deliveryDetails['client_name']}</div>
                <div className="order-client-db_ref">
                  Código: {props.deliveryDetails['client_db_ref']}
                </div>
              </div>
              <hr />
              <div>
                <div className="show-data">
                  <div>
                    <b>Número de despacho:</b>
                  </div>
                  <div>{props.deliveryDetails['delivery_db_ref']}</div>
                </div>
                <div className="show-data">
                  <div>
                    <b>Fecha de creación:</b>
                  </div>
                  <div>{getFormattedDate(props.deliveryDetails['created_date'])}</div>
                </div>
                <div className="show-data">
                  <div>
                    <b>Entregador/Repartidor/Conductor</b>
                  </div>
                  <div>{props.deliveryDetails['driver_name']}</div>
                </div>
                <div className="show-data">
                  <div>
                    <b>Fecha de Entrega:</b>
                  </div>
                  <div>{moment(props.deliveryDetails['delivery_date']).format('YYYY-MM-DD')}</div>
                </div>

                <div className="show-data">
                  <div>
                    <b>Productos por Entregar:</b>
                  </div>
                </div>
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>CÓDIGO</th>
                      <th>NOMBRE DEL PRODUCTO</th>
                      <th>CANTIDAD</th>
                      <th>PESO NETO</th>
                      <th>UNIDAD DE VENTA</th>
                      <th>PRECIO </th>
                      <th>TOTAL</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.deliveryDetails['details'].map((model, index) => (
                      <tr key={index}>
                        <td>{model['product_db_ref']}</td>
                        <td>{model['product_name']}</td>
                        <td>{Number(model['quantity']).toFixed(0)}</td>
                        <td>{Number(model['weight']).toFixed(2)}</td>
                        <td>{model['unit_of_sale']}</td>
                        <td>${Number(model['unit_price']).toFixed(2)}</td>
                        <td>${Number(model['extended_price']).toFixed(2)}</td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan="5" />
                      <td>
                        <b>PESO TOTAL</b>
                      </td>
                      <td>{Number(props.deliveryDetails['total_weight']).toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td colSpan="5" />
                      <td>
                        <b>SUB-TOTAL</b>
                      </td>
                      <td>${Number(props.deliveryDetails['total']).toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td colSpan="5" />
                      <td className="fs-20">
                        <b>TOTAL</b>
                      </td>
                      <td className="fs-20">
                        <b>${Number(props.deliveryDetails['total']).toFixed(2)}</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      )}
    </DispatchConsumer>
  )
}

export default DispatchDetails
