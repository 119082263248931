import React from 'react'
import cookie from 'react-cookies'
import { endsVowel } from '../helpers/utils'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

export default class ShowPromoView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isFetching: true,
      detailsData: null,
      id: window.location.href.split('/')[window.location.href.split('/').length - 1]
    }
  }

  componentDidMount() {
    this.fetchDetailsFromServer()
  }

  fetchDetailsFromServer = () => {
    this.setState({ isFetching: true })
    fetch(BACKEND_URL + '/promos/' + this.state.id, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      }
    })
      .then((response) => {
        if (response.status === 401) {
          window.location = '/'
        }
        return response.json()
      })
      .then((data) => this.setState({ detailsData: data, isFetching: false }))
      .catch((e) => console.log(e))
  }

  suspendPromotion = () => {
    var response = window.confirm('Desea continuar con la suspension de la promoción?')
    if (response === true) {
      fetch(BACKEND_URL + '/promos/' + this.state.id, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      })
        .then((data) => (window.location = '/promociones/' + this.state.id))
        .catch((e) => console.log(e))
    }
  }

  getFormattedDate = (createdDate) => {
    const d = new Date(createdDate)
    return d.toLocaleString()
  }

  getPackageBonusFormat = (quantity, pck) => {
    if (quantity > 1 && pck) {
      return `${endsVowel(pck) ? '(s)' : '(es)'}`
    } else {
      return ''
    }
  }

  render() {
    if (this.state.isFetching === false) {
      return (
        <div className="main-view">
          <div className="main-show-header">
            <h2>
              Promoción:{' '}
              {this.state.detailsData['applies_to'][0]['db_ref'] +
                ' - ' +
                this.state.detailsData['applies_to'][0]['name']}
            </h2>
          </div>
          <div>
            <div className="index-table">
              <div className="show-area">
                <div className="show-header">
                  <h2>Detalles de promoción:</h2>
                  <div className="show-buttons">
                    {(this.state.detailsData['status'] === 'active' ||
                      this.state.detailsData['status'] === 'scheduled') && (
                      <input
                        type="submit"
                        className="suspend-button"
                        onClick={this.suspendPromotion}
                        value="Suspender promoción"
                      />
                    )}
                  </div>
                </div>
                <hr className="mt-70" />
                <div>
                  <div className="show-data">
                    <div>
                      <b>Fecha de la promoción:</b>
                    </div>
                    <div style={{ height: 'auto' }}>
                      <div>Inicio</div>
                      <div>
                        {this.state.detailsData['start_time']
                          .split('T')[0]
                          .replace('-', '/')
                          .replace('-', '/')}
                      </div>
                    </div>
                    <div style={{ height: 'auto' }}>
                      <div>Fin</div>
                      <div>
                        {this.state.detailsData['end_time']
                          .split('T')[0]
                          .replace('-', '/')
                          .replace('-', '/')}
                      </div>
                    </div>
                  </div>
                  <div className="show-data">
                    <div>
                      <b>Tipo de promoción:</b>
                    </div>
                    {this.state.detailsData['promo_type'] === 'bonus_product' && (
                      <div>Bonificación de una referencia</div>
                    )}
                    {this.state.detailsData['promo_type'] === 'price_reduction' &&
                      this.props.shouldViewUpgradePromo && <div>Precio por descuento</div>}
                    {this.state.detailsData['promo_type'] === 'price_reduction' &&
                      !this.props.shouldViewUpgradePromo && <div>Precio rebajado</div>}
                    {this.state.detailsData['promo_type'] === 'scale_prices_reduction' && (
                      <div>Precio rebajado por escalas</div>
                    )}
                  </div>
                  <div className="show-data">
                    <div>
                      <b>Cliente:</b>
                    </div>
                    <div style={{ height: 'auto' }}>
                      {this.state.detailsData['applies_to'][0]['db_ref'] +
                        ' - ' +
                        this.state.detailsData['applies_to'][0]['name']}
                    </div>
                  </div>
                  <div className="show-data">
                    <div>
                      <b>Producto:</b>
                    </div>
                    {this.state.detailsData['promo_type'] === 'bonus_product' && (
                      <div style={{ height: 'auto' }}>
                        {this.state.detailsData['promotion']['base_products'][0]['db_ref'] +
                          ' - ' +
                          this.state.detailsData['promotion']['base_products'][0]['name']}
                      </div>
                    )}
                    {this.state.detailsData['promo_type'] === 'price_reduction' && (
                      <div style={{ height: 'auto' }}>
                        {this.state.detailsData['promotion']['product']['db_ref'] +
                          ' - ' +
                          this.state.detailsData['promotion']['product']['name']}
                      </div>
                    )}
                    {this.state.detailsData['promo_type'] === 'scale_prices_reduction' && (
                      <div style={{ height: 'auto' }}>
                        {this.state.detailsData['promotion']['products'][0]['db_ref'] +
                          ' - ' +
                          this.state.detailsData['promotion']['products'][0]['name']}
                      </div>
                    )}
                  </div>
                  {this.state.detailsData['promo_type'] === 'price_reduction' &&
                    this.props.shouldViewUpgradePromo && (
                      <div className="show-data">
                        <div>
                          <b>Descuento aplicado:</b>
                        </div>
                        <div>{parseFloat(this.state.detailsData['promotion']['discount'])}%</div>
                      </div>
                    )}

                  {this.state.detailsData['promo_type'] === 'price_reduction' &&
                    !this.props.shouldViewUpgradePromo && (
                      <div className="show-data">
                        <div>
                          <b>Precio Nuevo:</b>
                        </div>
                        <div>{this.state.detailsData['promotion']['price']}</div>
                      </div>
                    )}
                  {this.state.detailsData['promo_type'] === 'bonus_product' && (
                    <div className="show-data">
                      <div>
                        <b>Regla de promoción:</b>
                      </div>
                      <div>
                        {this.props.shouldViewUpgradePromo &&
                          this.state.detailsData['promotion']['base_quantity'] +
                            ' ' +
                            this.state.detailsData['promotion']['base_package'] +
                            this.getPackageBonusFormat(
                              this.state.detailsData['promotion']['base_quantity'],
                              this.state.detailsData['promotion']['base_package']
                            ) +
                            ' ' +
                            ' + ' +
                            this.state.detailsData['promotion']['bonus_quantity'] +
                            ' ' +
                            this.state.detailsData['promotion']['bonus_package'] +
                            this.getPackageBonusFormat(
                              this.state.detailsData['promotion']['bonus_quantity'],
                              this.state.detailsData['promotion']['bonus_package']
                            )}
                        {!this.props.shouldViewUpgradePromo &&
                          this.state.detailsData['promotion']['base_quantity'] +
                            ' ' +
                            this.state.detailsData['promotion']['base_products'][0]['price_unit'] +
                            ' + ' +
                            this.state.detailsData['promotion']['bonus_quantity'] +
                            ' ' +
                            this.state.detailsData['promotion']['bonus_products'][0]['price_unit']}
                      </div>
                    </div>
                  )}
                  {this.state.detailsData['promo_type'] === 'scale_prices_reduction' && (
                    <div className="show-data">
                      <div>
                        <b>Regla de promoción:</b>
                      </div>
                      <div style={{ height: 'auto' }}>
                        {Object.keys(this.state.detailsData['promotion']['scale_prices_hash']).map(
                          (key) => (
                            <div key={key}>
                              {(key.includes('INFINITY') ? '+' + key.split('-')[0] : key) +
                                ' la venta es a $' +
                                this.state.detailsData['promotion']['scale_prices_hash'][key]}
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  )}
                  <div className="show-data">
                    <div>
                      <b>Estado de promoción:</b>
                    </div>
                    {this.state.detailsData['status'] === 'active' && <div>Activa</div>}
                    {this.state.detailsData['status'] === 'expired' && <div>Caducada</div>}
                    {this.state.detailsData['status'] === 'scheduled' && <div>No Iniciada</div>}
                    {this.state.detailsData['status'] === 'suspended' && <div>Suspendida</div>}
                  </div>
                  <div className="show-data">
                    <div>
                      <b>Fecha de creación:</b>
                    </div>
                    <div>{this.getFormattedDate(this.state.detailsData['created_at'])}</div>
                  </div>
                  <div className="show-data">
                    <div>
                      <b>Fecha de actualización:</b>
                    </div>
                    <div>{this.getFormattedDate(this.state.detailsData['updated_at'])}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="main-view">
          <div className="show-title">
            <p>Obteniendo datos ....</p>
          </div>
        </div>
      )
    }
  }
}
