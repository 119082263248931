import React, { createContext, useEffect, useReducer, useState } from 'react'
import cookie from 'react-cookies'
import * as apiStatusCodes from '../../constants/apiStatusCodes'
import { useHistory } from 'react-router-dom'
import { getAllUsers, handleErrors } from '../services/apiUserService'

const initialState = {}
const store = createContext(initialState)

const { Provider } = store

const UserListProvider = (props) => {
  const [isFetching, setFetching] = useState(true)
  const [usersData, setUsersData] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [title, setTitle] = useState('')
  const [failedCreationState, setFailedCreationState] = useState(false)
  const [failedCreationInfo, setFailedCreationInfo] = useState('')
  const [paginationParams, setPaginationParams] = useState({
    initLoad: [],
    totalCount: 0
  })

  const getCurrentPage = (page) => {
    fetUserList(page)
  }

  const history = useHistory()

  useEffect(() => {
    fetUserList()
  }, [])

  const fetUserList = (page = 1) => {
    setFetching(true)
    const queryParams = getQueryState()
    getAllUsers(queryParams, page, 25)
      .then((response) => handleErrors(response))
      .then((response) => response.json())
      .then((data) => {
        setFetching(false)
        setUsersData(data['table'])
        setTotalCount(data['count'])
        setTitle(queryParams ? `Resultados de la búsqueda: ${queryParams}` : `Usuarios`)
      })
      .catch((error) => {
        evaluateResponse(error)
        if (error.status === apiStatusCodes.UNAUTHORIZED) {
          cookie.remove('token', { path: '/' })
          window.location = '/'
        }
      })
  }

  const evaluateResponse = (obj) => {
    switch (obj.status) {
      case 422:
        setFailedCreationState(true)
        //setTimeout(hideFailedCreationMessage, 5000);
        break
      case 404:
        setFailedCreationState(true)
        setFailedCreationInfo('Servidor fuera de servicio')
        break
      default:
        break
    }
  }

  const getQueryState = () => {
    if (window.location.href.indexOf('query') >= 0) {
      return decodeURI(
        window.location.href.split('query=')[window.location.href.split('query=').length - 1]
      )
    } else {
      return ''
    }
  }

  const handleCreateNewDeliverUser = () => {
    history.push('/crear_usuario_configuracion')
  }

  const handleShowUserConfigDetails = (event) => {
    event.preventDefault()
    const id = event.target.id
    history.push('/usuarios/' + id)
  }

  return (
    <Provider
      value={{
        isFetching,
        usersData,
        totalCount,
        title,
        handleCreateNewDeliverUser,
        handleShowUserConfigDetails,
        getCurrentPage,
        paginationParams
      }}
    >
      {props.children}
    </Provider>
  )
}
export { store, UserListProvider }
