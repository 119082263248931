import React, { useContext, useState } from 'react'
import ReactDOM from 'react-dom'
import Pagination from '../layout/Pagination'
import { GlobalContext } from '../global_context/GlobalContext'

const PromoIndexView = (props) => {
  const [alertState, setAlertSate] = useState('alert alert-success')

  const showModel = (event) => {
    event.preventDefault()
    const id = event.target.id
    window.location = '/promociones/' + id
  }

  const redirectToCreatePromoPage = () => {
    window.location = '/nueva_promo'
  }

  const hiddenAlert = () => {
    setAlertSate('alert alert-success hidden')
  }
  const data = props.data
  const { variants } = useContext(GlobalContext)

  if (props.isFetching) {
    return (
      <div className="main-view">
        <div className="index-header">
          <br />
          <p>Obteniendo datos ....</p>
        </div>
      </div>
    )
  } else {
    return (
      <div className="main-view">
        {props.successCreateForNewPromo && (
          <div className={alertState} role="alert">
            <button type="button" className="close" onClick={hiddenAlert} aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <strong>La promoción nueva fue creada exitosamente.</strong>
          </div>
        )}
        <div className="index-header">
          <h2>{props.title}</h2>
          <div className="index-buttons">
            <button onClick={redirectToCreatePromoPage}>Crear promoción nueva</button>
          </div>
        </div>
        <div className="index-table">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>ESTADO</th>
                <th>CLIENTE / LISTA DE PRECIO</th>
                <th>PRODUCTO</th>
                <th>PROMOCION</th>
              </tr>
            </thead>
            <tbody>
              {data.length > 0 &&
                data.map((model, index) => (
                  <tr className="cursor-pointer" key={index}>
                    {model['status'] === 'active' && (
                      <td id={model['id']} onClick={showModel}>
                        Activa
                      </td>
                    )}
                    {model['status'] === 'expired' && (
                      <td id={model['id']} onClick={showModel}>
                        Caducada
                      </td>
                    )}
                    {model['status'] === 'scheduled' && (
                      <td id={model['id']} onClick={showModel}>
                        No Iniciada
                      </td>
                    )}
                    {model['status'] === 'suspended' && (
                      <td id={model['id']} onClick={showModel}>
                        Suspendida
                      </td>
                    )}
                    <td id={model['id']} onClick={showModel}>
                      {model['applies_to'][0]['db_ref'] + ' - ' + model['applies_to'][0]['name']}
                    </td>
                    <td id={model['id']} onClick={showModel}>
                      {model['products'][0] ? model['products'][0]['db_ref'] : ''}
                    </td>
                    {model['promo_type'] === 'bonus_product' && (
                      <td id={model['id']} onClick={showModel}>
                        Bonificación de una referencia
                      </td>
                    )}
                    {model['promo_type'] === 'price_reduction' && (
                      <td id={model['id']} onClick={showModel}>
                        {variants.shouldViewUpgradePromo && <>Precio por descuento</>}
                        {!variants.shouldViewUpgradePromo && <>Precio rebajado</>}
                      </td>
                    )}
                    {model['promo_type'] === 'scale_prices_reduction' && (
                      <td id={model['id']} onClick={showModel}>
                        Precio rebajado por escalas
                      </td>
                    )}
                  </tr>
                ))}
              {data.length === 0 && (
                <tr>
                  <td>No hubo resultados</td>
                </tr>
              )}
            </tbody>
          </table>
          <nav>
            {props.total_pages > 1 && (
              <Pagination
                current_page={props.current_page}
                total_pages={props.total_pages}
              />
            )}
          </nav>
        </div>
      </div>
    )
  }
}

export default PromoIndexView
