import React from 'react'
import cookie from 'react-cookies'
import ReactDOM from 'react-dom'
import NavBar from '../layout/NavBar'
import TopBar from '../layout/TopBar'
import CreateOrderView from './CreateOrderView'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

export default class CreateOrderApp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      searchPlaceholder: 'Buscar pedidos por código o nombre de cliente'
    }
  }

  componentDidMount() {
    this.isUserLoggedIn()
  }

  isUserLoggedIn = () => {
    fetch(BACKEND_URL + '/users/current', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      }
    })
      .then((response) => response.json())
      .then((data) => {
        if (Object.keys(data).length === 0) {
          window.location = '/'
        }
      })
      .catch((e) => console.log(e))
  }

  handleSearch = (event) => {
    // search action occurs after ENTER is pressed
    if (event.keyCode === 13) {
      // to disbale default submit form
      event.preventDefault()
      const query = event.target.value
      const val = window.confirm(
        '¿Esta seguro que quiere continuar? Los cambios editados no seran guardados'
      )
      if (val) {
        if (query !== '') {
          // redirecting to index accroding to clicked
          window.location = '/pedidos?query=' + query
        }
      }
      event.target.value = ''
    }
  }

  render() {
    return (
      <div>
        <div>
          <div onKeyDown={this.handleSearch}>
            <TopBar searchPlaceholder={this.state.searchPlaceholder} />
          </div>
          <div>
            <CreateOrderView />
          </div>
        </div>
        <div>
          <NavBar activeItem="Pedidos" isEditing={true} />
        </div>
      </div>
    )
  }
}
