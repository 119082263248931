import React, { useContext } from 'react'
import { GlobalContext } from '../../../global_context/GlobalContext'

const CreateOrderInput = (props) => {
  const { variants } = useContext(GlobalContext)

  const redirectToCreateOrderPage = (e) => {
    props.redirectToCreateOrderPage()
  }

  return (
    <div>
      {variants.shouldCreateOrder ? (
        <div className="index-buttons">
          <button onClick={redirectToCreateOrderPage}>Crear pedido nuevo</button>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  )
}

export default CreateOrderInput
