import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import TopBar from './layout/TopBar'
import NavBar from './layout/NavBar'

const ProtectedRoute = ({
  component: Component,
  visible: visible,
  redirect: redirect,
  ...rest
}) => (
  <div>
    {visible != null && redirect !== false ? (
      <Route
        render={(props) => (visible ? <Component {...props} /> : <Redirect to={redirect} />)}
        {...rest}
      />
    ) : (
      <div>
        <div>
          <TopBar />
          <div className="main-view">
            <div className="show-title">
              <p>Obteniendo datos ...</p>
            </div>
          </div>
          <div>
            <NavBar />
          </div>
        </div>
      </div>
    )}
  </div>
)

export default ProtectedRoute
