import React from 'react'
import NavBar from '../layout/NavBar'
import TopBar from '../layout/TopBar'
import * as keyboardCodes from '../constants/keyboardCodes'
import { UserListProvider } from './context/UserListProvider'
import ListUserConfig from './ListUserConfig'

const IndexUserConfig = () => {
  const searchPlaceholder = 'Buscar usuario por código o nombre'

  const handleCreateNewDeliverUser = () => {
    this.props.history.push('/creat_usuarios')
  }

  const handleSearch = (event) => {
    if (event.keyCode === keyboardCodes.ENTER_KEY) {
      event.preventDefault()
      const query = event.target.value
      if (query !== '') {
        window.location = '/usuarios?query=' + query
      } else {
        window.location = '/usuarios'
      }
    }
  }

  return (
    <UserListProvider>
      <div>
        <div onKeyDown={handleSearch}>
          <TopBar searchPlaceholder={searchPlaceholder} />
        </div>
        <NavBar activeItem="Usuarios" isEditing={false} />
      </div>
      <ListUserConfig />
    </UserListProvider>
  )
}

export default IndexUserConfig
