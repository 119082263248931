import React, { createContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { getBusiness, getQueryState, handleErrors } from '../services/apiUserService'
import { handleTabClick } from '../helpers/UserConfigHandlers'
import * as apiStatusCodes from '../../constants/apiStatusCodes'
import cookie from 'react-cookies'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'

const initialState = {}

const UserConfigCreateContext = createContext(initialState)

const UserConfigCreateProvider = (props) => {
  const [failedCreationInfo, setFailedCreationInfo] = useState(null)
  const [formIsValid, setFormIsValid] = useState(false)

  const [isFetching, setFetching] = useState(true)
  const [businessData, setBusinessData] = useState({})
  const [formMessage, setFormMessage] = useState('')

  // form validation rules
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Este es un campo obligatorio.')
      .min(3, 'Asegúrese de cumplir con el mínimo de los 3 caracteres'),
    active: Yup.bool(),
    code: Yup.string()
      .required('Este es un campo obligatorio.')
      .min(3, 'Asegúrese de cumplir con el mínimo de los 3 caracteres'),
    email: Yup.string().required('Este es un campo obligatorio.').email('El formato no es válido.'),
    password: Yup.string()
      .min(8, 'Asegúrese de cumplir con el mínimo de los 8 caracteres')
      .required('Este es un campo obligatorio.'),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref('password'), null],
        'El valor en el campo de contraseña y reescribir contraseña deben de ser iguales.'
      )
      .required('Este es un campo obligatorio.'),
    mobile: Yup.string().notRequired().nullable()
  })

  const formOptions = { resolver: yupResolver(validationSchema) }
  const { register, handleSubmit, formState } = useForm(formOptions)
  const { errors, isValid } = formState

  const history = useHistory()

  useEffect(() => {
    getBusinessConfig()
  }, [])

  const getBusinessConfig = () => {
    setFetching(true)
    const queryParams = getQueryState()
    getBusiness()
      .then((response) => handleErrors(response))
      .then((response) => response.json())
      .then((data) => {
        setFetching(false)
        setBusinessData(data)
      })
      .catch((error) => {
        if (error.status === apiStatusCodes.UNAUTHORIZED) {
          cookie.remove('token', { path: '/' })
          window.location = '/'
        }
      })
  }

  const handleCancelStep = (event) => {
    const value = handleTabClick(event, true)
    if (value.confirm) history.push('/usuarios/')
  }

  return (
    <UserConfigCreateContext.Provider
      value={{
        failedCreationInfo,
        formIsValid,
        businessData,
        formMessage,
        errors,
        register,
        handleSubmit,
        isValid,
        handleCancelStep
      }}
    >
      {props.children}
    </UserConfigCreateContext.Provider>
  )
}
export { UserConfigCreateContext, UserConfigCreateProvider }
