import React from 'react'
import ReactDOM from 'react-dom'
import NavBar from '../layout/NavBar'
import TopBar from '../layout/TopBar'
import ShowProductView from '../product/ShowProductView'

export default class ShowProductApp extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      userName: 'Ana Sofia Lau Hou',
      searchPlaceholder: 'Buscar productos por código o nombre',
      isEditing: false
    }
  }

  handleSearch = (event) => {
    // search action occurs after ENTER is pressed
    if (event.keyCode === 13) {
      // to disbale default submit form
      event.preventDefault()
      const query = event.target.value
      if (query !== '') {
        if (this.state.isEditing) {
          const val = window.confirm(
            'Esta seguro que quiere continuar? Los cambios editados no seran guardados'
          )
          if (val === true) {
            window.location = '/productos?query=' + query
          }
        } else {
          window.location = '/productos?query=' + query
        }
        event.target.value = ''
      }
    }
  }

  passEditState = (val) => {
    this.setState({ isEditing: val })
  }

  render() {
    return (
      <div>
        <div>
          <div onKeyDown={this.handleSearch}>
            <TopBar
              searchPlaceholder={this.state.searchPlaceholder}
              userName={this.state.userName}
            />
          </div>
          <div>
            <ShowProductView
              editStateChanged={this.passEditState}
              isEditing={this.state.isEditing}
              successCreateForNewProduct={this.props.successCreateForNewProduct}
            />
          </div>
        </div>
        <div>
          <NavBar activeItem="Productos" isEditing={this.state.isEditing} />
        </div>
      </div>
    )
  }
}
