import React, { createContext, useReducer } from 'react'
import { BonusPromoReducer } from './BonusPromoReducer'

export const BonusPromoContext = createContext()

const BonusPromoContextProvider = ({ children }) => {
  const [bonusProducts, dispatch] = useReducer(BonusPromoReducer, [], () => [])

  const addBonusProduct = (payload) => {
    dispatch({ type: 'ADD_TO_BONUS_PRODUCT', payload })
  }

  const changeBaseQuantityProduct = (payload) => {
    dispatch({ type: 'CHANGE_BASE_QUANTITY_PRODUCT', payload })
  }

  const changeBasePackageProduct = (payload) => {
    dispatch({ type: 'CHANGE_BASE_PACKAGE_PRODUCT', payload })
  }

  const changeBonusQuantityProduct = (payload) => {
    dispatch({ type: 'CHANGE_BONUS_QUANTITY_PRODUCT', payload })
  }

  const changeBonusPackageProduct = (payload) => {
    dispatch({ type: 'CHANGE_BONUS_PACKAGE_PRODUCT', payload })
  }

  const removeBonusProduct = (payload) => {
    dispatch({ type: 'REMOVE_BONUS_PRODUCT', payload })
  }

  const clearBonusProduct = () => {
    dispatch({ type: 'CLEAR_BONUS_PRODUCT' })
  }
  const contextValues = {
    addBonusProduct,
    removeBonusProduct,
    changeBaseQuantityProduct,
    changeBasePackageProduct,
    changeBonusQuantityProduct,
    changeBonusPackageProduct,
    clearBonusProduct,
    bonusProducts
  }

  return <BonusPromoContext.Provider value={contextValues}>{children}</BonusPromoContext.Provider>
}

export default BonusPromoContextProvider
