import cookie from 'react-cookies'
import * as apiStatusCodes from '../../constants/apiStatusCodes'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL
const API_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  Authorization: 'Bearer ' + cookie.load('token')
}

export const getProductInfoToCreate = () => {
  const url = BACKEND_URL + `/businesses/1/products/new`
  return sendApiRequest('GET', url)
}

const sendApiRequest = (method, url, params) =>
  fetch(url, {
    method: method,
    headers: API_HEADERS,
    body: JSON.stringify(params)
  })

export const getBusiness = () => {
  const url =
    BACKEND_URL + `/admin/businesses?` + 'business_tenant_uid=' + cookie.load('business_tenant_uid')
  return sendApiRequest('GET', url)
}

export const handleErrors = (response) =>
  new Promise((resolve, reject) => {
    if (response.status !== apiStatusCodes.OK && response.status !== apiStatusCodes.CREATED) {
      reject(response)
    } else {
      resolve(response)
    }
  })

export const getAllUsers = (query = '', page = 1, per_page = 25) => {
  const url =
    BACKEND_URL +
    '/admin/salesmen?' +
    'business_tenant_uid=' +
    cookie.load('business_tenant_uid') +
    '&q=' +
    query +
    '&page=' +
    page +
    '&per_page=' +
    per_page
  return sendApiRequest('GET', url)
}

export const getAuditUser = (id, query = '', page = 1, per_page = 25) => {
  const url =
    BACKEND_URL +
    `/admin/user_config_audits/?` +
    'business_tenant_uid=' +
    cookie.load('business_tenant_uid') +
    '&id=' +
    id +
    '&q=' +
    query +
    '&page=' +
    page +
    '&per_page=' +
    per_page
  return sendApiRequest('GET', url)
}

export const getUseConfig = (id) => {
  const url =
    BACKEND_URL +
    `/admin/salesmen/${id}?` +
    'business_tenant_uid=' +
    cookie.load('business_tenant_uid')
  return sendApiRequest('GET', url)
}

export const updateUserInfo = (id, value) => {
  const url =
    BACKEND_URL +
    `/admin/users/${id}?` +
    'business_tenant_uid=' +
    cookie.load('business_tenant_uid')
  const params = {
    name: value.name,
    active: value.active,
    email: value.email,
    mobile: value.mobile
  }
  return sendApiRequest('PUT', url, params)
}

export const createUserInfo = (user, config) => {
  const url =
    BACKEND_URL + `/admin/salesmen?` + 'business_tenant_uid=' + cookie.load('business_tenant_uid')
  const params = {
    can_access_refunds_app: config.can_access_refunds_app.value,
    can_change_prices: config.can_change_prices.value,
    can_set_prices_on_quote_items: config.can_set_prices_on_quote_items.value,
    has_fixed_route: config.has_fixed_route.value,
    can_consolidate_orders: config.can_consolidate_orders.value,
    can_see_customer_segment: config.can_see_customer_segment.value,
    enable_fiscal_printer: config.enable_fiscal_printer.value,
    can_select_discount: config.can_select_discount.value,
    route: user.code,
    name: user.name,
    email: user.email,
    db_ref: user.code,
    mobile: user.mobile,
    active: user.active,
    key: user.password,
    key_confirmation: user.confirmPassword
  }
  return sendApiRequest('POST', url, params)
}

export const sendEmail = (user, action) => {
  const url =
    BACKEND_URL +
    `/admin/send_email_to_user?` +
    'business_tenant_uid=' +
    cookie.load('business_tenant_uid')
  const params = {
    email: user.email,
    key: user.password,
    email_type: action
  }
  return sendApiRequest('POST', url, params)
}

export const resetPassword = (user) => {
  const url =
    BACKEND_URL +
    `/admin/update_password?` +
    'business_tenant_uid=' +
    cookie.load('business_tenant_uid')
  const params = {
    email: user.email,
    key: user.password,
    key_confirmation: user.password_confirmation
  }
  return sendApiRequest('POST', url, params)
}

export const updateSalesmanConfig = (id, value) => {
  const url =
    BACKEND_URL +
    `/admin/salesmen/${id}?` +
    'business_tenant_uid=' +
    cookie.load('business_tenant_uid')
  const params = {
    can_access_refunds_app: value.can_access_refunds_app.value,
    can_change_prices: value.can_change_prices.value,
    can_set_prices_on_quote_items: value.can_set_prices_on_quote_items.value,
    has_fixed_route: value.has_fixed_route.value,
    can_consolidate_orders: value.can_consolidate_orders.value,
    can_see_customer_segment: value.can_see_customer_segment.value,
    enable_fiscal_printer: value.enable_fiscal_printer.value,
    can_select_discount: value.can_select_discount.value,
    route: value.route.value,
    email: value.email.value,
    salesman_email: value.email.value
  }
  return sendApiRequest('PUT', url, params)
}

export const getQueryState = () => {
  if (window.location.href.indexOf('query') >= 0) {
    return decodeURI(
      window.location.href.split('query=')[window.location.href.split('query=').length - 1]
    )
  } else {
    return ''
  }
}

export const getFormattedDate = (createdDate) => {
  const d = new Date(createdDate)
  return d.toLocaleString()
}
