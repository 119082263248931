import React from 'react'
import cookie from 'react-cookies'
import Pagination from '../layout/Pagination'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

export default class ProductHistoryTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isFetching: true,
      data: [],
      current_page: 1,
      per_page: 25,
      total_pages: 1
    }
  }

  componentDidMount() {
    this.fetchDataFromServer()
  }

  fetchDataFromServer = () => {
    this.setState({ isFetching: true })
    fetch(
      BACKEND_URL +
        '/products/' +
        this.props.id +
        '/product_price_audits' +
        '?page=' +
        this.state.current_page +
        '&per_page=' +
        this.state.per_page,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      }
    )
      .then((response) => response.json())
      .then((data) =>
        this.setState({
          data: data['table'],
          isFetching: false,
          total_pages: Math.ceil(data['count'] / this.state.per_page)
        })
      )
      .catch((e) => console.log(e))
  }

  handlePagination = (event) => {
    if (event.target.id === 'first-page') {
      if (this.state.current_page !== 1) {
        this.setState({ current_page: 1 }, this.fetchDataFromServer)
      }
    } else if (event.target.id === 'last-page') {
      if (this.state.current_page !== this.state.total_pages) {
        this.setState({ current_page: this.state.total_pages }, this.fetchDataFromServer)
      }
    } else if (event.target.id === 'previous-page') {
      if (this.state.current_page !== 1) {
        this.setState(
          { current_page: parseInt(this.state.current_page) - 1 },
          this.fetchDataFromServer
        )
      }
    } else if (event.target.id === 'next-page') {
      if (this.state.current_page !== this.state.total_pages) {
        this.setState(
          { current_page: parseInt(this.state.current_page) + 1 },
          this.fetchDataFromServer
        )
      }
    }
  }

  getFormattedDate = (createdDate) => {
    const d = new Date(createdDate)
    return d.toLocaleString()
  }

  render() {
    const data = this.props.data
    return (
      <div>
        <div className="show-header show-product-header">
          <h2>Detalles del historial de los cambios de producto:</h2>
        </div>
        <div className="mt-50">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>NOMBRE</th>
                <th>DESCRIPCION</th>
                <th>FECHA</th>
              </tr>
            </thead>
            <tbody>
              {this.state.isFetching == false &&
                this.state.data.length > 0 &&
                this.state.data.map((obj, index) => (
                  <tr key={index}>
                    <td>{obj['user_email']}</td>
                    <td>{obj['description']}</td>
                    <td>{this.getFormattedDate(obj['timestamp'])}</td>
                  </tr>
                ))}
              {this.state.isFetching == false && this.state.data.length == 0 && (
                <tr>
                  <td colSpan="3">No hay historal para este producto.</td>
                </tr>
              )}
              {this.state.isFetching == true && (
                <tr>
                  <td colSpan="3">Obteniendo datos...</td>
                </tr>
              )}
            </tbody>
          </table>
          <nav onClick={this.handlePagination.bind(this)}>
            {this.state.total_pages > 1 && (
              <Pagination
                current_page={this.state.current_page}
                total_pages={this.state.total_pages}
              />
            )}
          </nav>
        </div>
      </div>
    )
  }
}
