import React from 'react'
import cookie from 'react-cookies'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

export default class ProductPriceListRow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isEditing: false,
      price: this.props.obj.price
    }
  }

  startEditing = () => {
    this.setState({ isEditing: true })
  }

  cancelEditing = () => {
    this.setState({ isEditing: false })
  }

  unassignPriceList = (event) => {
    const val = window.confirm(
      'Estas desasignando una lista de precio para este producto. Deseas continuar?'
    )
    if (val === true) {
      event.preventDefault()
      fetch(BACKEND_URL + '/product_pricelist/' + this.props.obj.id, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        },
        body: JSON.stringify({
          id: this.props.obj.id,
          price: 0.0,
          active: false
        })
      })
        .then((response) => response.json())
        .then((data) => (window.location = '/productos/' + this.props.id + '/listas_de_precios'))
        .catch((e) => console.log(e))
    }
  }

  editPrice = (event) => {
    event.preventDefault()
    fetch(BACKEND_URL + '/product_pricelist/' + this.props.obj.id, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      },
      body: JSON.stringify({
        id: this.props.obj.id,
        price: this.state.price,
        active: true
      })
    })
      .then((response) => response.json())
      .then((data) => (window.location = '/productos/' + this.props.id + '/listas_de_precios'))
      .catch((e) => console.log(e))
  }

  handleInputChange(event) {
    const obj = {}
    obj[event.target.name] = event.target.value
    this.setState(obj)
  }

  render() {
    const obj = this.props.obj
    return (
      <tr>
        <td id="price_list_db_ref">{obj.db_ref}</td>
        <td id="price_list_name">{obj.name}</td>
        <td>
          <span className="mr-20">$</span>
          {this.state.isEditing === false && <span id="current_price_span">{obj.price}</span>}
          {this.state.isEditing === true && (
            <input
              required
              onChange={this.handleInputChange.bind(this)}
              className="price-input"
              name="price"
              step="0.01"
              type="number"
              placeholder={obj.price}
            />
          )}
        </td>
        <td className="text-align-center">
          {this.state.isEditing === false && (
            <div>
              <input
                onClick={this.startEditing.bind(this)}
                className="edit-price-button"
                value="Actualizar"
                type="submit"
              />
              <button
                onClick={this.unassignPriceList.bind(this)}
                className="unassign-price-list-button"
                type="button"
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </div>
          )}
          {this.state.isEditing === true && (
            <div>
              <input
                onClick={this.cancelEditing.bind(this)}
                id="cancel-edit"
                className="cancel-edit-price-button"
                value="Cancelar"
                type="submit"
              />
              <input
                onClick={this.editPrice.bind(this)}
                className="edit-price-button"
                type="submit"
                value="Guardar"
              />
            </div>
          )}
        </td>
      </tr>
    )
  }
}
