import React, { useContext } from 'react'
import ReactDOM from 'react-dom'
import NavBar from '../layout/NavBar'
import TopBar from '../layout/TopBar'
import ShowPromoView from '../promo/ShowPromoView'
import { GlobalContext } from '../global_context/GlobalContext'

const ShowPromoApp = () => {
  const userName = 'Ana Sofia Lau Hou'
  const searchPlaceholder = 'Buscar promociones por código o nombre de producto'
  const { variants } = useContext(GlobalContext)

  const handleSearch = (event) => {
    // search action occurs after ENTER is pressed
    if (event.keyCode === 13) {
      // to disbale default submit form
      event.preventDefault()
      const query = event.target.value
      if (query !== '') {
        window.location = '/promociones?query=' + query
        event.target.value = ''
      }
    }
  }

  return (
    <div>
      <div>
        <div onKeyDown={handleSearch}>
          <TopBar searchPlaceholder={searchPlaceholder} userName={userName} />
        </div>
        <div>
          <ShowPromoView shouldViewUpgradePromo={variants.shouldViewUpgradePromo} />
        </div>
      </div>
      <div>
        <NavBar activeItem="Promociones" />
      </div>
    </div>
  )
}

export default ShowPromoApp
