import React from 'react'
import ReactDOM from 'react-dom'
import NavBar from '../layout/NavBar'
import TopBar from '../layout/TopBar'
import ShowPriceListView from '../price_list/ShowPriceListView'

export default class ShowPriceListApp extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      userName: 'Ana Sofia Lau Hou',
      searchPlaceholder: 'Buscar listas de precios por código o nombre'
    }
  }

  handleSearch = (event) => {
    // search action occurs after ENTER is pressed
    if (event.keyCode === 13) {
      // to disbale default submit form
      event.preventDefault()
      const query = event.target.value
      if (query !== '') {
        window.location = '/listas_de_precios?query=' + query
        event.target.value = ''
      }
    }
  }

  render() {
    return (
      <div>
        <div>
          <div onKeyDown={this.handleSearch}>
            <TopBar
              searchPlaceholder={this.state.searchPlaceholder}
              userName={this.state.userName}
            />
          </div>
          <div>
            <ShowPriceListView />
          </div>
        </div>
        <div>
          <NavBar activeItem="Listas de Precios" isEditing={false} />
        </div>
      </div>
    )
  }
}
