import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { Button, Form, FormControl, InputGroup, Nav, Navbar, NavDropdown } from 'react-bootstrap'

export default class TopBar extends React.Component {
  render() {
    return (
      <div className="top-bar">
        {this.props.searchPlaceholder !== '' && (
          <div>
            <FontAwesomeIcon className="icon-search" icon={faSearch} />
            <input className="search-input-topbar" placeholder={this.props.searchPlaceholder} />
          </div>
        )}
      </div>
    )
  }
}
