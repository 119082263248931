import React, { useEffect, useState } from 'react'
import cookie from 'react-cookies'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileUpload, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import ImageUploading from 'react-images-uploading'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

const ProductPhotoTab = (props) => {
  const business_id = 1
  const [isFetching, setFetching] = useState(true)
  const [data, setData] = useState([])
  const [images, setImages] = useState([])
  const [failedCreation, setFailedCreationState] = useState(false)
  const [isAddingImage, setAddingImageState] = useState(false)
  const maxNumber = 1
  const maxMbFileSize = 5242880

  useEffect(() => {
    setImages([])
    fetchDataFromServer()
  }, [])

  const fetchDataFromServer = () => {
    setFetching(true)
    fetch(
      BACKEND_URL + '/businesses/' + business_id + '/products/' + props.id + '/product_photos',
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setData(data)
        setFetching(false)
      })
      .catch((e) => console.log(e))
  }

  const setAddingImage = () => {
    setAddingImageState(true)
  }

  const unsetAddingImage = () => {
    setAddingImageState(false)
    setImages([])
  }

  const deleteImage = (event) => {
    if (window.confirm('Estas removiendo la imagen para este producto. Deseas continuar?')) {
      event.preventDefault()
      const photo_id = event.currentTarget.id
      fetch(BACKEND_URL + '/product_photos/' + photo_id, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      })
        .then(() => (window.location = '/productos/' + props.id + '/imagenes'))
        .catch((e) => console.log(e))
    }
  }

  const setFailedCreation = () => {
    setFailedCreationState(failedCreation)
    setTimeout(hideFailedCreationMessage, 5000)
  }

  const hideFailedCreationMessage = () => {
    setFailedCreationState(false)
  }

  const handleSubmitImage = (e) => {
    e.preventDefault()
    if (images.length > 0) {
      fetch(BACKEND_URL + '/product_photos', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        },
        body: JSON.stringify({
          business_id: 1,
          product_id: props.id,
          photo_name: images[0].file.name,
          url: images[0].data_url
        })
      })
        .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
        .then((obj) => evaluateResponse(obj))
        .catch((e) => console.log(e))
    }
  }

  const evaluateResponse = (obj) => {
    if (obj.status === 201) {
      window.location = '/productos/' + props.id + '/imagenes'
    } else {
      setFailedCreation()
    }
  }

  const onChangeImage = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex)
    setImages(imageList)
  }

  return (
    <div>
      {failedCreation === true && (
        <div className="alert alert-danger" role="alert">
          <strong>No se pudo agregar imagen al producto. Error en el sistema.</strong>
        </div>
      )}
      {isAddingImage === true && (
        <div>
          <form onSubmit={handleSubmitImage}>
            <div className="show-header show-product-header">
              <p className="left-header-form-title">Imagenes del producto</p>
              <div className="cancel-save-block">
                <input
                  id="cancel-add"
                  onClick={() => unsetAddingImage()}
                  className="cancel-edit-button"
                  value="Cancelar"
                  type="submit"
                />
                <input
                  value="Guardar"
                  className="save-button"
                  type="submit"
                  disabled={images.length === 0}
                />
              </div>
            </div>
            <hr />
            <div className="form-group row show-data-form">
              <div className="col-md-3">
                <p style={{ fontSize: 13, color: '#AAAAAA' }}>
                  Notas:
                  <ul>
                    <li>Imagen debe ser formato PNG o JPG.</li>
                    <li>Máximo de 1 imagen.</li>
                    <li>Tamaño máximo de 600px x 600 px.</li>
                    <li>Tamaño de archivo inferior a 5 MB.</li>
                  </ul>
                </p>
              </div>
              <div className="col-sm-8">
                <ImageUploading
                  multiple
                  value={images}
                  onChange={onChangeImage}
                  maxNumber={maxNumber}
                  maxFileSize={maxMbFileSize}
                  resolutionWidth={600}
                  resolutionHeight={600}
                  resolutionType={'less'}
                  acceptType={['jpg', 'png']}
                  dataURLKey="data_url"
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                    errors
                  }) => (
                    <div className="upload__image-wrapper">
                      <div className="form-group row show-data-form">
                        <div className="">
                          <div
                            className="upload-drop-zone"
                            id="drop-zone"
                            style={isDragging ? { color: 'red' } : undefined}
                            onClick={onImageUpload}
                            {...dragProps}
                          >
                            <span className="drag-drop-icon">
                              <FontAwesomeIcon
                                className="drag-drop-icon"
                                size="5x"
                                icon={faFileUpload}
                              />
                            </span>
                            <span>Haz drag and drop o haz clic en este botón</span>
                          </div>
                        </div>
                        {errors && (
                          <div style={{ color: '#E10000' }}>
                            {errors.maxNumber && <span>Máximo de 1 imagen por producto</span>}
                            {errors.acceptType && (
                              <span>Verifique que la imagen sea formato .png .jpg</span>
                            )}
                            {errors.resolution && (
                              <span>
                                Verifique que la imagen sea formato .png .jpg y que las dimensiones
                                sean por debajo de 600px x 600px.
                              </span>
                            )}
                            {errors.maxFileSize && (
                              <span>El tamaño de la imagen no debe ser mayor a 5MB</span>
                            )}
                          </div>
                        )}
                      </div>

                      {imageList.map((image, index) => (
                        <div className="row" key={index}>
                          <div className="image-item">
                            <div className="col-md-6">
                              <img src={image['data_url']} alt="" width="100" />
                              <span>{image.name}</span>
                            </div>
                            <div className="col-md-6">
                              <div className="image-item__btn-wrapper">
                                <p
                                  className="delete-ubq-product-label"
                                  onClick={() => onImageRemove(index)}
                                >
                                  <FontAwesomeIcon icon={faTrashAlt} />
                                  Eliminar imagen
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </ImageUploading>
              </div>
            </div>
          </form>
        </div>
      )}
      {isAddingImage === false && (
        <>
          <div className="show-header show-product-header">
            <h2>Imagenes del producto:</h2>
            {isFetching === false && data['table'].length === 0 && (
              <input
                onClick={setAddingImage}
                className="float-right"
                value="+     Agregar imagen"
                type="submit"
              />
            )}
            {isFetching === false && data['table'].length > 0 && (
              <input
                disabled
                onClick={setAddingImage}
                className="inactive float-right"
                value="Agregar imagen"
                type="submit"
              />
            )}
          </div>
          <div className="mt-50">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>IMAGEN</th>
                  <th>NOMBRE</th>
                  <th className="text-align-center">ACCION</th>
                </tr>
              </thead>
              <tbody>
                {isFetching === false &&
                  data['table'].length > 0 &&
                  data['table'].map((obj) => (
                    <tr key={obj.id}>
                      <td>
                        <img className="" alt="foto" src={obj['url']} />
                      </td>
                      <td>{obj['photo_name']}</td>
                      <td className="text-align-center">
                        <button
                          className="unassign-price-list-button"
                          id={obj['id']}
                          onClick={deleteImage}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </button>
                      </td>
                    </tr>
                  ))}
                {isFetching === false && data['table'].length === 0 && (
                  <tr>
                    <td colSpan="3">No existen imágenes para este producto.</td>
                  </tr>
                )}
                {isFetching === true && (
                  <tr>
                    <td colSpan="3">Obteniendo datos...</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  )
}

export default ProductPhotoTab
