import React, { useState } from 'react'
import { ClientEcommerceConsumer } from '../context/ClientEcommerceContext'
import TextInput from '../../components/inputs/TextInput'

const EcommerceUserRow = ({ obj }) => {
  const getFormattedDate = (createdDate) => {
    const d = new Date(createdDate)
    return d.toLocaleString()
  }

  const [isEditing, setEditing] = useState(false)

  const startEditing = (e) => {
    e.preventDefault()
    setEditing(true)
  }

  const cancelEditing = (e) => {
    e.preventDefault()
    setEditing(false)
  }

  return (
    <ClientEcommerceConsumer>
      {({
        formUpdateControls,
        changeUpdateHandler,
        formSubmitUpdate,
        handleUpdateState,
        formSubmitSuspend
      }) => (
        <tr className="cursor-pointer" key={obj.id}>
          <td>{obj.active === true ? 'ACTIVO' : 'SUSPENDIDO'}</td>
          <td>
            {isEditing === false && <span>{obj.name}</span>}
            {isEditing === true && (
              <TextInput
                name="name"
                inputstyle="form-control"
                style={{ marginTop: 10 }}
                placeholder={formUpdateControls.name.placeholder}
                value={formUpdateControls.name.value}
                touched={formUpdateControls.name.touched.toString()}
                valid={formUpdateControls.name.valid.toString()}
                onChange={changeUpdateHandler}
              />
            )}
          </td>
          <td>{obj.email}</td>
          <td>
            {isEditing === false && <span>{obj.mobile} </span>}
            {isEditing === true && (
              <TextInput
                name="phone"
                inputstyle="form-control"
                style={{ marginTop: 10 }}
                placeholder={formUpdateControls.phone.placeholder}
                value={formUpdateControls.phone.value}
                touched={formUpdateControls.phone.touched.toString()}
                valid={formUpdateControls.phone.valid.toString()}
                onChange={changeUpdateHandler}
              />
            )}
          </td>
          <td>{getFormattedDate(obj.updated_at)}</td>
          <td className="text-align-center">
            <div className="container-fluid">
              {isEditing === false && obj.active === true && (
                <div className="row">
                  <button
                    className="ubiqua-button-detail-danger"
                    value="Suspender"
                    onClick={(e) => formSubmitSuspend(e, obj)}
                    style={{ marginBottom: 10 }}
                    type="submit"
                  >
                    Suspender
                  </button>
                  <button
                    onClick={(e) => {
                      startEditing(e)
                      handleUpdateState(e, obj)
                    }}
                    className="ubiqua-button-detail"
                    value="Actualizar"
                    type="submit"
                  >
                    Actualizar
                  </button>
                </div>
              )}
              {isEditing === true && (
                <div className="row">
                  <button
                    id="cancel-edit"
                    style={{ marginBottom: 10 }}
                    onClick={(e) => cancelEditing(e)}
                    className="cancel-edit-price-button"
                    value="Cancelar"
                    type="submit"
                  >
                    Cancelar
                  </button>
                  <button
                    className="edit-price-button"
                    type="submit"
                    onClick={(e) => formSubmitUpdate(e, obj)}
                    value="Guardar"
                  >
                    Guardar
                  </button>
                </div>
              )}
            </div>
          </td>
        </tr>
      )}
    </ClientEcommerceConsumer>
  )
}
export default EcommerceUserRow
