export const getTimestampFromDateTime = (date) => {
  const d = new Date(date)
  return d.toLocaleString()
}

export const getCurrentDate = () => new Date().getFullYear() + '-' + getMonth() + '-' + getDay()

export const getMonth = () => {
  const m = new Date().getMonth() + 1
  if (m < 10) {
    return '0' + m
  } else {
    return m
  }
}

export const getDay = () => {
  const d = new Date().getDate()
  if (d < 10) {
    return '0' + d
  } else {
    return d
  }
}

export const getStartDate = () => {
  if (window.location.href.indexOf('start_date') >= 0) {
    return window.location.href
      .split('start_date=')
      [window.location.href.split('start_date=').length - 1].split('&')[0]
  } else {
    return getCurrentDate()
  }
}

export const getEndDate = () => {
  if (window.location.href.indexOf('end_date') >= 0) {
    return window.location.href
      .split('end_date=')
      [window.location.href.split('end_date=').length - 1].split('&')[0]
  } else {
    return getCurrentDate()
  }
}
